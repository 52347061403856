import Service from '../../services/Service'

export const namespaced = true

export const state = {
  orders: null,
  selectedOrder: null,
}

export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  EDIT_ORDER(state, order) {
    const index = state.orders.findIndex(eachOrder => eachOrder.id === order.id)
    state.orders[index].trackingNumber = order.trackingNumber
  },
}

export const actions = {
  async getOrder({ commit }, shopId) {
    try {
      const { data } = await Service.getOrder(shopId)
      commit('SET_ORDERS', data.results)
    } catch (err) {
      throw err
    }
  },
  async editOrder({ commit }, order) {
    try {
      const { data } = await Service.editOrder(order)
      console.log(data)
      commit('EDIT_ORDER', data)
    } catch (err) {
      throw err
    }
  },
}
