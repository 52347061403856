<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">รายการโปรโมชั่น</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right">
          <base-button size="md" type="primary" @click.native="goBack"
            >ย้อนกลับ</base-button
          >
          <base-button size="md" type="neutral" @click.native="onSubmit"
            >ยืนยันสินค้าเข้าร่วม</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <promotion-checkbox
        :tableProductData="tableProductData"
        :selected="productsPromotion"
        @selectedProducts="getSelectedProduct"
      ></promotion-checkbox>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components'
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin'
import jwtDecode from 'jwt-decode'
import PromotionCheckbox from './CheckboxPromotionTable.vue'
import NProgress from 'nprogress'
import api from '@/services/Service.js'
// import users from './users2'

export default {
  mixins: [clientPaginationMixin],
  components: {
    // BasePagination,
    RouteBreadCrumb,
    PromotionCheckbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mounted() {
    this.getUserInfo()
    this.selectedPromotion = JSON.parse(
      localStorage.getItem('selectedPromotion')
    )
    api
      .getProductsPromotion(this.selectedPromotion.id)
      .then(res => {
        this.productsPromotion = res.data.results
      })
      .catch(err => {
        console.log(err)
      })
  },
  data() {
    return {
      // propsToSearch: ['name', 'email', 'age'],
      chooseProduct: false,
      loading: true,
      rejected: false,
      approved: false,
      selectedPromotion: {},
      propsToSearch: ['name'],
      tableColumns: [
        {
          type: 'selection',
        },
        {
          prop: 'startingDate',
          label: 'วันที่เริ่ม',
          minWidth: 100,
          sortable: true,
        },
        {
          prop: 'endingDate',
          label: 'สิ้นสุดโปรโมชั่น',
          minWidth: 100,
          sortable: true,
        },
        {
          prop: 'name',
          label: 'รายชื่อโปรโมชั่น',
          minWidth: 250,
          sortable: true,
        },
        {
          prop: 'status',
          label: 'สถานะ',
          minWidth: 100,
          sortable: true,
        },
      ],
      tableData: [],
      selectedRows: [],
      selectedProducts: [],
      tableProductData: [],
      productsPromotion: [],
    }
  },
  methods: {
    async onSubmit() {
      const numberOfProducts = this.selectedProducts.length
      let count = 0
      NProgress.start()

      //ถ้าในรายการโปรโมชั่นมี แต่อันใหม่ไม่มีแล้ว = delete
      let deleteFromPromotion = this.productsPromotion.filter(
        x => !this.selectedProducts.some(y => y.id == x.id)
      )

      while (count < deleteFromPromotion.length) {
        this.$store.dispatch('shop/deleteProductPromotion', {
          productId: deleteFromPromotion[count].id,
          promotionId: this.selectedPromotion.id,
        })
        count++
      }
      count = 0
      while (count !== numberOfProducts) {
        await this.$store.dispatch(
          'shop/postProductPromotion',
          this.selectedProducts[count].id
        )
        count++
      }
      this.goBack()
      NProgress.done()
    },
    goBack(index, row) {
      this.chooseProduct = !this.chooseProduct
      this.$router.push({ name: 'Promotion List' })
      localStorage.removeItem('selectedPromotion')
    },
    getSelectedProduct(value) {
      this.selectedProducts = value
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    convertStartDate(data, date) {
      data.startingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    },
    convertEndDate(data, date) {
      data.endingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    },
    async getUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'))
      const decoded = jwtDecode(user.token)
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }

      await this.$store
        .dispatch('user/getUserInfo', userData.id)
        .then(() => {
          this.userInfo = this.$store.state.user.user.info
          this.getShopInfo()
        })
        .catch(err => {
          throw err
        })
    },
    async getShopInfo() {
      await this.$store
        .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
        .then(() => {
          this.shopInfo = this.$store.state.shop.shop
          this.getProductByShop()
        })
        .catch(err => {
          throw err
        })
    },
    async getProductByShop() {
      await this.$store.dispatch(
        'shop/getProductByShop',
        this.$store.state.user.user.info.shopId
      )
      this.tableProductData = this.$store.state.shop.shop.products
    },
  },
  computed: {
    noRejectList(row) {
      return this.tableData.filter(
        notRejected => notRejected.status !== 'Rejected'
      )
    },
  },
}
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
