<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">รายการโปรโมชั่น</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <!-- <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col> -->
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">โปรโมชั่นทั้งหมด</h3>
            <p class="text-sm mb-0">
              ตรวจสอบโปรโมชั่นของร้านค้าทั้งหมดใน Fortune Town
            </p>
          </template>
          <div v-if="!loading">
            <b-col
              cols="12"
              class="d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search Promotion..."
                >
                </base-input>
              </div>
            </b-col>

            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column
                min-width="180px"
                align="center"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="table-actions">
                  <el-tooltip
                    v-if="row.status == 'Pending'"
                    content="ยืนยันโปรโมชั่น"
                    placement="top"
                  >
                    <a
                      href="#!"
                      @click.prevent="handleApprove($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="Approve Promotion"
                    >
                      <i
                        class="fas fa-check"
                        style="color: green; font-size: 1.3rem"
                      ></i>
                    </a> </el-tooltip
                  >&nbsp;
                  <el-tooltip
                    v-if="row.status == 'Pending'"
                    content="ปฏิเสธโปรโมชั่น"
                    placement="top"
                  >
                    <a
                      href="#!"
                      @click.prevent="handleReject($index, row)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      data-original-title="Reject Promotion"
                    >
                      <i
                        class="fas fa-times"
                        style="color: red; font-size: 1.5rem"
                      ></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>

              <!-- <el-table-column
                min-width="180px"
                align="right"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="d-flex">
                  <base-button
                    v-if="row.status == 'Pending'"
                    @click.native="handleApprove($index, row)"
                    class="like btn-link"
                    type="success"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-check-bold"></i>
                  </base-button>
                  <base-button
                    v-if="row.status == 'Pending'"
                    @click.native="handleReject($index, row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-fat-remove"></i>
                  </base-button>
                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; เลือก {{ selectedRows.length }} รายการ
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components'
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin'
// import jwtDecode from 'jwt-decode'
import swal from 'sweetalert2'
import Service from '../../services/Service.js'
// import users from './users2'

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    this.getPromotion()
  },
  data() {
    return {
      loading: true,
      count: 0,
      rejected: false,
      approved: false,
      searchQuery: '',
      propsToSearch: ['name', 'shopName'],
      tableColumns: [
        {
          prop: 'startingDate',
          label: 'วันที่เริ่ม',
          minWidth: 100,
          sortable: true,
        },
        {
          prop: 'endingDate',
          label: 'สิ้นสุดโปรโมชั่น',
          minWidth: 100,
          sortable: true,
        },
        {
          prop: 'name',
          label: 'รายชื่อโปรโมชั่น',
          minWidth: 200,
          sortable: true,
        },
        {
          prop: 'shopName',
          label: 'เลขที่ร้านค้า',
          minWidth: 120,
          sortable: true,
        },
        {
          prop: 'status',
          label: 'สถานะ',
          minWidth: 100,
          sortable: true,
        },
      ],
      tableData: [],
      selectedRows: [],
    }
  },
  methods: {
    async getPromotion() {
      await this.$store.dispatch('admin/getPromotions').then(() => {
        this.tableData = this.$store.state.admin.promotions
        this.tableData.forEach(async row => {
          const { data } = await Service.getShopById(row.shopId)
          row.shopName = data.name
        })

        this.tableData.forEach(row => {
          const startIsoDate = new Date(row.startDate)
          const endIsoDate = new Date(row.endDate)
          this.convertStartDate(row, startIsoDate)
          this.convertEndDate(row, endIsoDate)
        })

        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    // async getOrder() {
    //   await this.$store
    //     .dispatch('order/getOrder', this.$store.state.shop.shop.id)
    //     .then(() => {
    //       this.tableData = this.$store.state.order.orders;
    //       this.tableData.forEach(row => {
    //         const isoDate = new Date(row.created_at);
    //         this.convertDate(row, isoDate);
    //       });
    //       this.loading = false;
    //     })
    //     .catch(error => {
    //       throw error;
    //     });
    // },
    convertStartDate(data, date) {
      data.startingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    },
    convertEndDate(data, date) {
      data.endingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill',
      })
    },
    handleApprove(index, row) {
      swal
        .fire({
          title: 'ยอมรับโปรโมชั่นนี้',
          text: `คุณจะไม่สามารถย้อนกลับได้`,
          type: 'success',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'ยอมรับ',
          cancelButtonText: 'ยกเลิก',
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            if (this.selectedRows.length > 1) {
              this.selectedRows.forEach(row =>
                this.$store
                  .dispatch('admin/approvePromotion', row)
                  .then(() => {})
              )
              location.reload()
            } else {
              this.$store.dispatch('admin/approvePromotion', row).then(() => {
                location.reload()
              })
            }
          }
        })
    },
    handleReject(index, row) {
      swal
        .fire({
          title: 'ปฏิเสธโปรโมชั่นนี้',
          text: `คุณจะไม่สามารถย้อนกลับได้!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'ปฏิเสธโปรโมชั่น',
          cancelButtonText: 'ยกเลิก',
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            if (this.selectedRows.length > 1) {
              this.selectedRows.forEach(row =>
                this.$store
                  .dispatch('admin/rejectPromotion', row)
                  .then(() => {})
              )
              location.reload()
            } else {
              this.$store.dispatch('admin/rejectPromotion', row).then(() => {
                location.reload()
              })
            }
          }
        })
    },
    // handleEdit(index, row) {
    //   swal.fire({
    //     title: `You want to edit ${row.productName}`,
    //     buttonsStyling: false,
    //     confirmButtonClass: 'btn btn-info btn-fill',
    //   })
    // },
    // handleDelete(index, row) {
    // swal
    //   .fire({
    //     title: 'Are you sure?',
    //     text: `You won't be able to revert this!`,
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonClass: 'btn btn-success btn-fill',
    //     cancelButtonClass: 'btn btn-danger btn-fill',
    //     confirmButtonText: 'Yes, delete it!',
    //     buttonsStyling: false,
    //   })
    //     .then(result => {
    //       if (result.value) {
    //         this.deleteRow(row);
    //         swal.fire({
    //           title: 'Deleted!',
    //           text: `You deleted ${row.name}`,
    //           type: 'success',
    //           confirmButtonClass: 'btn btn-success btn-fill',
    //           buttonsStyling: false,
    //         });
    //       }
    //     });
    // },
    // deleteRow(row) {
    //   let indexToDelete = this.tableData.findIndex(
    //     tableRow => tableRow.id === row.id
    //   );
    //   if (indexToDelete >= 0) {
    //     this.tableData.splice(indexToDelete, 1);
    //   }
    // },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    // async getUserInfo() {
    //   const user = JSON.parse(localStorage.getItem('user'));
    //   const decoded = jwtDecode(user.token);
    //   const userData = {
    //     id: decoded.sub,
    //     username: decoded.username,
    //     roleName: decoded.role,
    //   };

    //   await this.$store
    //     .dispatch('user/getUserInfo', userData.id)
    //     .then(() => {
    //       this.userInfo = this.$store.state.user.user.info;
    //       this.getShopInfo();
    //     })
    //     .catch(err => {
    //       throw err;
    //     });
    // },
    // async getShopInfo() {
    //   await this.$store
    //     .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
    //     .then(() => {
    //       this.shopInfo = this.$store.state.shop.shop;
    //       this.getOrder();
    //     })
    //     .catch(err => {
    //       throw err;
    //     });
    // },
  },
}
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
