import Vue from 'vue';
import Vuex from 'vuex';
import * as user from '@/store/modules/user.js';
import * as shop from '@/store/modules/shop.js';
import * as order from '@/store/modules/order.js';
import * as admin from '@/store/modules/admin.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    shop,
    order,
    admin,
  },
  state: {},
});
