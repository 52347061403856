<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Welcome to Fortune Town!</h1>
              <p class="text-lead text-white">
                เพื่อบริหารร้านค้าของคุณ เข้าสู่ระบบเลย
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <!-- <b-card-header class="bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/github.svg"
                  /></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/google.svg"
                  /></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>กรอกอีเมลเพื่อเข้าสู่ระบบ</small>
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-email-83"
                    placeholder="อีเมล"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    :custom-messages="EmailMessages"
                    v-model="user.username"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3 password--field"
                    prepend-icon="ni ni-lock-circle-open"
                    placeholder="รหัสผ่าน"
                    ref="password"
                    type="password"
                    name="password"
                    :rules="{ required: true, min: 6 }"
                    :custom-messages="PasswordMessages"
                    v-model="user.password"
                  >
                  </base-input>
                  <p class="error-message">{{ errorMessage }}</p>

                  <!-- <div class="below-input-container">
                   
                    <router-link
                      to=""
                      class="text-right text-light forget-password"
                      >ลืมรหัสผ่าน?</router-link
                    >
                  </div> -->

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4 sign-in-btn"
                      >เข้าสู่ระบบ</base-button
                    >
                  </div>
                  <!-- <p class="no-account">
                    ยังไม่ได้ลงทะเบียนกับ Fortune Town?
                    <router-link to="shop-register"
                      >ลงทะเบียนที่นี่</router-link
                    >
                  </p> -->
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import jwtDecode from 'jwt-decode'
import { apiClient } from '../../services/Service'

export default {
  data() {
    return {
      rememberMe: false,
      user: this.createFreshUserObject(),
      userData: {},
      admin: false,
      isVerified: false,
      errorMessage: '',
      EmailMessages: {
        required: 'กรุณากรอกอีเมล',
        email: 'กรุณากรอกอีเมลให้ถูกต้อง',
      },
      PasswordMessages: {
        required: 'กรุณากรอกรหัสผ่าน',
        min: 'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
      },
    }
  },
  methods: {
    onSubmit() {
      NProgress.start()

      this.$store
        .dispatch('user/login', this.user)
        .then(async () => {
          this.errorMessage = ''
          await this.checkAdmin()
          await this.checkIsVerified()
          this.$store
            .dispatch('user/getUserInfo', this.$store.state.user.user.id)
            .then(() => {
              if (this.isVerified === true) {
                if (this.admin) {
                  this.$store.state.user.user.info = {
                    first_name: 'Admin',
                  }
                  this.$router.push({
                    path: `/dashboard/admin/admin-dashboard/shop-list`,
                  })
                } else if (
                  !this.$store.state.user.user.info ||
                  this.$store.state.user.user.info.shopId === null
                ) {
                  this.$router.push({
                    path: `/create-profile/${this.userData.id}`,
                  })
                } else {
                  this.$store
                    .dispatch(
                      'shop/getShopInfo',
                      this.$store.state.user.user.info.shopId
                    )
                    .then(() => {
                      this.$router.push({
                        path: `/dashboard/${this.userData.id}/${this.$store.state.user.user.info.shopId}/product-list`,
                      })
                      // console.log(this.$store.state)
                    })
                }
              } else {
                this.$router.push({
                  path: `/shop-verify/${this.userData.id}`,
                })
              }
            })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.errorMessage =
              'รหัสผ่านไม่ถูกต้อง กรุณากรอกรหัสผ่านใหม่อีกครั้ง'
          }
          if (error.response.status === 500) {
            this.errorMessage =
              'บัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง'
          }
        })
        .finally(() => NProgress.done())
    },
    createFreshUserObject() {
      return {
        username: '',
        password: '',
      }
    },
    decodeToken() {
      const user = JSON.parse(localStorage.getItem('user'))
      const decoded = jwtDecode(user.token)
      this.userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }
    },
    checkAdmin() {
      this.decodeToken()

      if (this.userData.roleName == 'Admin') {
        this.admin = true
      }
    },
    async checkIsVerified() {
      this.decodeToken()

      const { data } = await apiClient.get(`v1/users/${this.userData.id}`)

      this.isVerified = data.isVerified
    },
  },
}
</script>

<style>
.below-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forget-password {
  font-size: 0.875rem;
  color: #525f7f !important;
}

.sign-in-btn {
  width: 100%;
}

.no-account {
  text-align: center;
  font-size: 0.9rem;
}

.error-message {
  font-size: 0.9rem;
  color: red;
}
</style>
