import Service from '../../services/Service.js'
import jwtDecode from 'jwt-decode'

export const namespaced = true

export const state = {
  user: null,
  file: null,
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData
  },
  SET_TOKEN(state, token) {
    state.user.token = token.token
    localStorage.setItem('user', JSON.stringify(token))
    // apiClient.defaults.headers.common['Authorization'] = `Bearer ${token.token}`
  },
  REFRESH_TOKEN(state, token) {
    state.user.token = token.token
    localStorage.setItem('user', JSON.stringify(token))
  },
  SET_USER_INFO(state, userInfo) {
    state.user.info = userInfo
  },
  CLEAR_USER_DATA(state) {
    localStorage.clear()
    location.reload()
  },
  SET_FILE(state, file) {
    state.file = file
  },
}

export const actions = {
  async createUser({ commit }, user) {
    try {
      const { data } = await Service.postUser(user)
      commit('SET_USER_DATA', data)
    } catch (err) {
      throw err
    }
  },
  async login({ commit }, user) {
    try {
      const { data } = await Service.postAuthLogin(user)
      const decoded = jwtDecode(data.token)
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }

      commit('SET_USER_DATA', userData)
      commit('SET_TOKEN', data)
    } catch (err) {
      throw err
    }
  },
  async getUserInfo({ commit }, userId) {
    try {
      const { data } = await Service.getUserInfo()
      const allUserInfo = data.results
      const userInfo = allUserInfo.filter(info => info.userId === userId)
      commit('SET_USER_INFO', userInfo[0])
    } catch (err) {
      throw err
    }
  },
  async addUserInfo({ commit }, userInfo) {
    try {
      const { data } = await Service.postUserInfo(userInfo)
      commit('SET_USER_INFO', data)
    } catch (err) {
      throw err
    }
  },
  async editUserInfo({ commit }, user) {
    const { data } = await Service.editUserInfo(user)
    commit('SET_USER_INFO', data)
  },
  async refreshToken({ commit }, token) {
    const { data } = await Service.postAuthRefresh(token)
    commit('REFRESH_TOKEN', data)
  },
  logOut({ commit }) {
    commit('CLEAR_USER_DATA')
  },
  async postFile({ commit }, file) {
    const { data } = await Service.uploadFile(file)
    console.log(data)
    commit('SET_FILE', data)
  },
}

export const getters = {
  loggedIn(state) {
    return !!state.user
  },
}
