<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">รายการส่วนลด</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right" v-if="selecting === true">
          <base-button size="md" type="neutral" @click="toggleSelect"
            >ยกเลิก</base-button
          >
          <base-button size="md" type="danger" @click="handleDelete"
            >ลบ {{ selectedRows.length }} รายการที่เลือก</base-button
          >
        </b-col>

        <b-col lg="6" cols="5" class="text-right" v-else>
          <base-button size="md" type="neutral" @click="toggleSelect"
            >เลือกรายการ</base-button
          >
          <base-button size="md" type="default" @click="addDiscount"
            >เพิ่มส่วนลด</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">ส่วนลดทั้งหมด</h3>
            <p class="text-sm mb-0">ตรวจสอบส่วนลดทั้งหมดของร้านค้า</p>
          </template>
          <div v-if="!loading">
            <b-col
              cols="12"
              class="d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div class="btn-search-container">
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search Discount..."
                >
                </base-input>
              </div>
            </b-col>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-if="selecting" type="selection" />

              <el-table-column
                label="รายการส่วนลด"
                min-width="250px"
                prop="discount_percent"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.discount_percent }}%
                </template>
              </el-table-column>

              <!-- <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column> -->
              <el-table-column
                min-width="180px"
                align="right"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="d-flex">
                  <el-tooltip
                    content="เลือกสินค้าเข้าร่วมส่วนลด"
                    placement="top"
                  >
                    <a
                      href="#!"
                      @click.prevent="toggleChooseProduct($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="View Product"
                    >
                      <i class="fas fa-clipboard"></i>
                    </a> </el-tooltip
                  >&nbsp;

                  <el-tooltip content="แก้ไขส่วนลด" placement="top">
                    <a
                      href="#!"
                      @click.prevent="editDiscount($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="Edit Product"
                    >
                      <i class="fas fa-edit"></i>
                    </a>
                  </el-tooltip>
                  <el-tooltip
                    v-if="selecting !== true"
                    content="ลบส่วนลด"
                    placement="top"
                  >
                    <a
                      href="#!"
                      @click.prevent="handleDelete($index, row)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      data-original-title="Delete Product"
                    >
                      <i
                        v-if="row.status != 'Approved'"
                        class="fas fa-trash"
                      ></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; เลือก {{ selectedRows.length }} รายการ
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components'
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin'
import jwtDecode from 'jwt-decode'
import swal from 'sweetalert2'
import NProgress from 'nprogress'
// import users from './users2'

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    this.getDiscount()
  },
  async mounted() {
    await this.getUserInfo()
  },
  data() {
    return {
      chooseProduct: false,
      loading: true,
      selecting: false,
      rejected: false,
      approved: false,
      searchQuery: '',
      propsToSearch: ['discount_percent'],
      // tableColumns: [
      //   {
      //     prop: 'discount_percent',
      //     label: 'รายการส่วนลด',
      //     minWidth: 250,
      //     sortable: true,
      //   },
      // ],
      tableData: [],
      selectedRows: [],
      selectedProducts: [],
      filterTableData: [],
    }
  },
  methods: {
    async getDiscount() {
      await this.$store
        .dispatch('shop/getProductDiscount', this.$route.params.ShopId)
        .then(() => {
          this.tableData = this.$store.state.shop.productDiscounts

          this.loading = false
        })
    },
    addDiscount() {
      swal
        .fire({
          title: 'เพิ่มส่วนลด<br><h3 style="color: #595959">(เป็นหน่วย %)</h3>',
          html: `<input
          type="text"
          class="swal2-input"
          placeholder="กรอกส่วนลด เช่น 20"
          id="discount-percent"
          >
          `,
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'เพิ่มส่วนลด',
          cancelButtonText: 'ยกเลิก',
          preConfirm: () => {
            const discountPercent = +swal
              .getPopup()
              .querySelector('#discount-percent').value
            if (!discountPercent) {
              swal.showValidationMessage(
                `ยังไม่ได้กรอกส่วนลดหรือกรอกส่วนลดไม่ถูกต้อง`
              )
            }

            return {
              discount_percent: discountPercent,
              shopId: this.$store.state.shop.shop.id,
            }
          },
        })
        .then(result => {
          this.postDiscount(result.value)
          // this.loading = true
          swal.fire(
            `
            เพิ่มส่วนลด ${result.value.discount_percent}% แล้ว
            `.trim()
          )
        })
    },
    editDiscount(index, row) {
      swal
        .fire({
          title: 'แก้ไขส่วนลด<br><h3 style="color: #595959">(เป็นหน่วย %)</h3>',
          html: `<input
          type="text"
          class="swal2-input"
          placeholder="กรอกส่วนลด เช่น 20"
          id="discount-percent"
          value=${row.discount_percent}
          >
          `,
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'แก้ไขส่วนลด',
          cancelButtonText: 'ยกเลิก',
          preConfirm: () => {
            const discountPercent = +swal
              .getPopup()
              .querySelector('#discount-percent').value
            if (!discountPercent) {
              swal.showValidationMessage(
                `ยังไม่ได้กรอกส่วนลดหรือกรอกส่วนลดไม่ถูกต้อง!`
              )
            }

            return {
              discount_percent: discountPercent,
              id: row.id,
            }
          },
        })
        .then(result => {
          this.patchDiscount(result.value)
          swal.fire(
            `
            แก้ไขส่วนลดเป็น ${result.value.discount_percent}% แล้ว
            `.trim()
          )
        })
    },
    patchDiscount(discountPercent) {
      this.$store
        .dispatch('shop/editProductDiscount', discountPercent)
        .then(() => {
          location.reload()
        })
    },
    postDiscount(discountPercent) {
      this.$store
        .dispatch('shop/createProductDiscount', discountPercent)
        .then(() => {
          location.reload()
        })
    },
    goToAddProductToPromotion() {
      this.$router.push({
        name: 'Promotion CheckBox List',
      })
    },
    toggleSelect() {
      this.selecting = !this.selecting
    },
    toggleChooseProduct(index, row) {
      this.chooseProduct = !this.chooseProduct
      if (this.chooseProduct === true) {
        localStorage.setItem('selectedDiscount', JSON.stringify(row))
      } else {
        localStorage.removeItem('selectedDiscount')
      }
      this.$router.push({
        path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/discount-list/${row.id}`,
      })
    },
    getSelectedProduct(value) {
      this.selectedProducts = value
    },
    convertStartDate(data, date) {
      data.startingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    },
    convertEndDate(data, date) {
      data.endingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    handleEdit(index, row) {
      localStorage.setItem('EditPromotion', JSON.stringify(row))
      this.$router.push({ path: `/dashboard/edit-promotion/${row.id}` })
    },
    handleDelete(index, row) {
      if (this.selecting === true && this.selectedRows.length === 0) {
        swal.fire({
          title: 'คุณยังไม่ได้เลือกรายการ',
          buttonsStyling: false,
          type: 'warning',
          confirmButtonClass: 'btn btn-warning btn-fill',
        })
        return
      }

      swal
        .fire({
          title: 'คุณแน่ใจใช่ไหม?',
          text: `คุณจะไม่สามารถย้อนกลับได้`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'ลบส่วนลด',
          cancelButtonText: 'ยกเลิก',
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            if (this.selectedRows.length > 1) {
              this.selectedRows.forEach(row => this.deleteRow(row))

              swal.fire({
                title: `ลบส่วนลดทั้ง ${this.selectedRows.length} รายการแล้ว`,
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false,
              })
            } else {
              this.deleteRow(row)
              swal.fire({
                title: `ลบส่วนลด ${row.discount_percent}% แล้ว`,
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false,
              })
            }
          }
        })
    },
    async deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      )
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }

      await this.$store
        .dispatch('shop/deleteProductDiscount', row.id)
        .catch(err => {
          throw err
        })
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'))
      const decoded = jwtDecode(user.token)
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }

      await this.$store
        .dispatch('user/getUserInfo', userData.id)
        .then(() => {
          this.userInfo = this.$store.state.user.user.info
          this.getShopInfo()
        })
        .catch(err => {
          throw err
        })
    },
    async getShopInfo() {
      await this.$store
        .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
        .then(() => {
          this.shopInfo = this.$store.state.shop.shop
        })
        .catch(err => {
          throw err
        })
    },
  },
  watch: {
    searchQuery(newVal, oldVal) {
      this.filterTableData = this.tableData.filter(
        data =>
          !newVal || data.name.toLowerCase().includes(newVal.toLowerCase())
      )
    },
  },
}
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.fa-clipboard:hover {
  color: aquamarine;
}
</style>
