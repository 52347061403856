<template>
  <div>
    <base-header class="pb-8">
      <b-row class="align-items-center py-4"> </b-row>
    </base-header>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <card>
        <b-row align-v="center" slot="header">
          <b-col cols="8">
            <h3 class="heading-h3 mb-0">แก้ไขข้อมูลร้านค้า</h3>
          </b-col>
        </b-row>

        <b-form @submit.prevent="onSubmit" v-if="!loading">
          <!-- USER INFO -->
          <!-- <h6 class="heading-small text-muted mb-4">ข้อมูลส่วนตัว</h6>

          <div class="pl-lg-4">
            <b-row>
              <b-col lg="6">
                <base-input
                  type="text"
                  label="* ชื่อจริง"
                  placeholder="ชื่อจริง"
                  v-model="userInfo.first_name"
                  :rules="{ required: true }"
                  :custom-messages="FirstNameMessages"
                >
                </base-input>
              </b-col>
              <b-col lg="6">
                <base-input
                  type="text"
                  label="* นามสกุล"
                  placeholder="นามสกุล"
                  v-model="userInfo.last_name"
                  :rules="{ required: true }"
                  :custom-messages="LastNameMessages"
                >
                </base-input>
              </b-col>
            </b-row>
          </div>
          <hr class="my-4" /> -->

          <!-- SHOP INFO -->
          <h6 class="heading-small text-muted mb-4">ข้อมูลร้านค้า</h6>

          <div class="pl-lg-4">
            <b-row>
              <b-col md="12">
                <base-input
                  type="text"
                  label="* ชื่อร้าน"
                  placeholder="ชื่อร้าน"
                  v-model="shop.name"
                  :rules="{ required: true }"
                  :custom-messages="ShopNameMessages"
                >
                </base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <base-input label="หมวดหมู่ร้าน">
                  <el-select
                    v-model="shop.categoryId"
                    filterable
                    placeholder="หมวดหมู่ร้าน"
                  >
                    <el-option
                      v-for="category in categories"
                      :key="category.name"
                      :label="category.name"
                      :value="category.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col lg="6">
                <base-input
                  label="เบอร์โทรศัพท์"
                  placeholder="เบอร์"
                  v-model="shop.phoneNumber"
                >
                </base-input>
              </b-col>

              <b-col md="12">
                <base-input
                  label="ที่อยู่ร้านค้า (ระบุชั้นและเลขที่ห้องใน Fortune Town)"
                  placeholder="ที่อยู่ร้านค้า"
                  v-model="shop.address"
                >
                </base-input>
              </b-col>

              <b-col lg="6">
                <base-input
                  label="Facebook ร้านค้า"
                  placeholder="Facebook ของร้าน"
                  v-model="shop.fbName"
                >
                </base-input>
              </b-col>

              <b-col lg="6">
                <base-input
                  label="Line ID ร้านค้า"
                  placeholder="Line ID ของร้าน"
                  v-model="shop.lineId"
                >
                </base-input>
              </b-col>

              <b-col md="12">
                <base-input
                  label="เว็บไซต์ร้านค้า"
                  placeholder="เว็บไซต์ร้านค้า"
                  v-model="shop.website"
                >
                </base-input>
              </b-col>

              <b-col md="12">
                <base-input
                  type="text"
                  label="รายละเอียดร้าน"
                  placeholder="รายละเอียดร้าน"
                >
                  <textarea
                    class="form-control"
                    rows="10"
                    placeholder="ใส่รายละเอียดสินค้า..."
                    resize="none"
                    v-model="shop.description"
                  ></textarea>
                </base-input>
              </b-col>
            </b-row>
            <b-row>
              <label class="col-md-2 col-form-label form-control-label"
                >ภาพปกร้าน</label
              >
              <!-- <b-col md="12">
                <base-input
                  label="รูปภาพร้าน"
                  placeholder="url รูปภาพร้าน"
                  v-model="shop.profile_image_url"
                >
                </base-input>
              </b-col> -->
              <b-col md="3">
                <div class="img-container">
                  <img v-bind:src="shop.profile_image_url" alt="" class="" />
                </div>
              </b-col>
              <b-col md="3">
                <dropzone-file-upload
                  v-model="img"
                  id="dzMessageCover"
                  required
                ></dropzone-file-upload>
              </b-col>
            </b-row>
          </div>

          <base-button
            class="mt-4"
            size="md"
            outline
            type="default"
            @click.native="goBack"
            >ย้อนกลับ</base-button
          >
          <base-button native-type="submit" type="primary" class="mt-4"
            >บันทึก</base-button
          >
        </b-form>
      </card>
    </b-container>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import axios from 'axios';
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
import { Dropzone } from 'dropzone';
import NProgress from 'nprogress';
// import Service from '@/services/Service'
// import { mapState } from 'vuex'
import jwtDecode from 'jwt-decode';

export default {
  name: 'register',
  components: {
    DropzoneFileUpload,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      userInfo: null,
      shop: null,
      img: [],
      categories: [],
      // FirstNameMessages: {
      //   required: 'กรุณากรอกชื่อจริง',
      // },
      // LastNameMessages: {
      //   required: 'กรุณากรอกนามสกุล',
      // },
      ShopNameMessages: {
        required: 'กรุณากรอกชื่อร้าน',
      },
      // ShopCatMessages: {
      //   required: 'กรุณาเลือกหมวดหมู่ร้าน',
      // },
      loading: true,
    };
  },
  computed: {
    // ...mapState(['shop', 'user']),
  },
  created() {
    this.$store.state.shop.apiClientAuth = axios.create({
      baseURL: 'https://api-ft-production.devfinite.solutions',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // prettier-ignore
        'Authorization': localStorage.getItem('user') ? `Bearer ${JSON.parse(localStorage.getItem('user')).token}` : '',
      },
    });
    this.getShopInfo();
  },
  mounted() {},
  methods: {
    processImg(value) {
      value = this.img;
      const data = new FormData();
      data.append('file', value[0]);
      var config = {
        method: 'post',
        url: 'https://api-ft-production.devfinite.solutions/v1/files/upload/profile',
        data: data,
      };

      const that = this;
      axios(config)
        .then(function (response) {
          that.shop.profile_image_url = response.data.url;
        })
        .catch(function (error) {
          throw new Error(error);
        });
    },
    async onSubmit() {
      NProgress.start();
      if (this.img !== '') {
        this.processImg();
      }
      setTimeout(async () => {
        await this.$store
          .dispatch('admin/editShop', this.shop)
          .then(() => {
            localStorage.removeItem('editShop');
          })
          .catch(error => {
            this.refreshErrorhandle(error);
            // this.onSubmit()
          })
          .finally(() => {
            NProgress.done();
            this.$router.push({
              name: 'Shop List',
            });
          });
        // this.editUserInfo()
      }, 500);
    },
    goBack(index, row) {
      localStorage.removeItem('editShop');
      this.$router.push({ name: 'Shop List' });
    },
    getCategoryId() {
      // this.categories.forEach(eachCategory => {
      //   if (eachCategory.name === this.shop.category) {
      //     eachCategory.id = this.shop.categoryId
      //   }
      // })
    },
    editUserInfo() {
      this.$store
        .dispatch('user/editUserInfo', this.userInfo)
        .then(() => {
          this.$router.go();
          this.$router.push({
            name: 'Dashboard',
          });
        })
        .catch(error => {
          this.refreshErrorhandle(error);
          // this.editUserInfo()
          console.log('done user refresh');
        })
        .finally(() => {
          NProgress.done();
        });
    },
    async getShopCategories() {
      await this.$store
        .dispatch('shop/getShopCategories')
        .then(responses => {
          responses.forEach(res => this.categories.push(res));
          this.loading = false;
        })
        .catch(error => console.log(error));
    },
    async getShopInfo() {
      const editingShop = JSON.parse(localStorage.getItem('editShop'));
      this.shop = editingShop;
      this.getShopCategories();
    },
    async refreshErrorhandle(error) {
      const err = JSON.parse(JSON.stringify(error));
      const user = JSON.parse(localStorage.getItem('user'));
      if (err.status === 445) {
        await this.$store
          .dispatch('user/refreshToken', user)
          .then(() => {
            this.$store.dispatch('shop/getNewUrl').then(() => {});
          })
          .catch(err => {
            throw err;
          });
      } else if (err.status === 446) {
        window.alert('Session Expired\nกรุณา Login ใหม่');
        this.$store.dispatch('user/logOut');
      }
    },
  },
};
</script>
<style>
.heading-h3 {
  font-size: 1.2rem;
}

.img-container {
  width: 12rem;
  height: 12rem;
  object-fit: cover;
}

.img-container img {
  width: 100%;
}

.heading-small {
  font-size: 0.9rem;
}
</style>
