<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">รายการอีเว้นท์</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right" v-if="selecting === true">
          <base-button size="md" type="neutral" @click="toggleSelect"
            >ยกเลิก</base-button
          >
          <base-button size="md" type="danger" @click="handleDelete"
            >ลบ {{ selectedRows.length }} รายการที่เลือก</base-button
          >
        </b-col>

        <b-col lg="6" cols="5" class="text-right" v-else>
          <base-button size="md" type="neutral" @click="toggleSelect"
            >เลือกรายการ</base-button
          >
          <base-button size="md" type="default" @click="goToAddEvent"
            >เพิ่มอีเว้นท์</base-button
          >
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">อีเว้นท์ทั้งหมด</h3>
            <p class="text-sm mb-0">ตรวจสอบอีเว้นท์ทั้งหมดของ Fortune Town</p>
          </template>
          <div v-if="!loading">
            <b-col
              cols="12"
              class="d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search Order..."
                >
                </base-input>
              </div>
            </b-col>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-if="selecting" type="selection" />

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column
                min-width="180px"
                align="center"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="table-actions">
                  <el-tooltip content="แก้ไขอีเว้นท์" placement="top">
                    <a
                      href="#!"
                      @click.prevent="handleEdit($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="Edit Event"
                    >
                      <i class="fas fa-edit" style="font-size: 1.2rem"></i>
                    </a> </el-tooltip
                  >&nbsp;
                  <el-tooltip
                    v-if="selecting !== true"
                    content="ลบอีเว้นท์"
                    placement="top"
                  >
                    <a
                      href="#!"
                      @click.prevent="handleDelete($index, row)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      data-original-title="Delete Event"
                    >
                      <i class="fas fa-trash" style="font-size: 1.2rem"></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>

              <!-- <el-table-column
                min-width="180px"
                align="right"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="d-flex">
                  <base-button
                    @click.native="handleEdit($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-ruler-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete($index, row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-fat-remove"></i>
                  </base-button>
                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; เลือก {{ selectedRows.length }} รายการ
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components'
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin'
import jwtDecode from 'jwt-decode'
import swal from 'sweetalert2'
// import users from './users2'

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    this.getEvent()
  },
  data() {
    return {
      // propsToSearch: ['name', 'email', 'age'],
      loading: true,
      selecting: false,
      propsToSearch: ['name', 'status'],
      tableColumns: [
        {
          prop: 'startingDate',
          label: 'วันที่เริ่ม',
          minWidth: 90,
          sortable: true,
        },
        {
          prop: 'endingDate',
          label: 'วันที่สิ้นสุด',
          minWidth: 90,
          sortable: true,
        },
        {
          prop: 'name',
          label: 'รายชื่ออีเว้นท์',
          minWidth: 250,
          sortable: true,
        },
        {
          prop: 'status',
          label: 'สถานะอีเว้นท์',
          minWidth: 100,
          sortable: true,
        },
      ],
      tableData: [],
      selectedRows: [],
    }
  },
  methods: {
    async getEvent() {
      await this.$store.dispatch('admin/getEvents').then(() => {
        this.tableData = this.$store.state.admin.events
        this.tableData.forEach(row => {
          const startIsoDate = new Date(row.startDate)
          const endIsoDate = new Date(row.endDate)
          this.convertStartDate(row, startIsoDate)
          this.convertEndDate(row, endIsoDate)
        })
        this.loading = false
      })
    },
    // async getOrder() {
    //   await this.$store
    //     .dispatch('order/getOrder', this.$store.state.shop.shop.id)
    //     .then(() => {
    //       this.tableData = this.$store.state.order.orders;
    //       this.tableData.forEach(row => {
    //         const isoDate = new Date(row.created_at);
    //         this.convertDate(row, isoDate);
    //       });
    //       this.loading = false;
    //     })
    //     .catch(error => {
    //       throw error;
    //     });
    // },
    convertStartDate(data, date) {
      // data.time = `${
      //   date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      // }:${
      //   date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      // }:${
      //   date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
      // }`;

      data.startingDate = `${date.toLocaleDateString('th-TH')}`
    },
    convertEndDate(data, date) {
      // data.time = `${
      //   date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      // }:${
      //   date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      // }:${
      //   date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
      // }`;

      data.endingDate = `${date.toLocaleDateString('th-TH')}`
    },
    goToAddEvent() {
      this.$router.push({ name: 'AddEvent' })
    },
    toggleSelect() {
      this.selecting = !this.selecting
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    // handleLike(index, row) {
    //   swal.fire({
    //     title: `You liked ${row.name}`,
    //     buttonsStyling: false,
    //     type: 'success',
    //     confirmButtonClass: 'btn btn-success btn-fill',
    //   });
    // },
    // handleView(index, row) {
    //   localStorage.setItem("EditEvent", JSON.stringify(row));
    //   this.$router.push({ name: "EditEvent" });
    // },
    handleEdit(index, row) {
      localStorage.setItem('EditEvent', JSON.stringify(row))
      this.$router.push({
        path: `edit-event/${row.id}`,
      })
    },
    handleDelete(index, row) {
      if (this.selecting === true && this.selectedRows.length === 0) {
        swal.fire({
          title: 'คุณยังไม่ได้เลือกรายการ',
          buttonsStyling: false,
          type: 'warning',
          confirmButtonClass: 'btn btn-warning btn-fill',
        })
        return
      }

      swal
        .fire({
          title: 'คุณแน่ใจใช่ไหม?',
          text: `คุณจะไม่สามารถย้อนกลับได้!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'ลบอีเว้นท์',
          cancelButtonText: 'ยกเลิก',
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            if (this.selectedRows.length > 1) {
              this.selectedRows.forEach(row => this.deleteRow(row))

              swal.fire({
                title: `ลบอีเว้นท์ทั้ง ${this.selectedRows.length} อีเว้นท์แล้ว`,
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false,
              })
            } else {
              this.deleteRow(row)
              swal.fire({
                title: `ลบอีเว้น "${row.name}" แล้ว`,
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false,
              })
            }
          }
        })
    },
    async deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      )
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }

      await this.$store
        .dispatch('admin/deleteEvent', row.id)
        .then()
        .catch(err => {
          throw err
        })
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    // async getUserInfo() {
    //   const user = JSON.parse(localStorage.getItem('user'));
    //   const decoded = jwtDecode(user.token);
    //   const userData = {
    //     id: decoded.sub,
    //     username: decoded.username,
    //     roleName: decoded.role,
    //   };

    //   await this.$store
    //     .dispatch('user/getUserInfo', userData.id)
    //     .then(() => {
    //       this.userInfo = this.$store.state.user.user.info;
    //       this.getShopInfo();
    //     })
    //     .catch(err => {
    //       throw err;
    //     });
    // },
    // async getShopInfo() {
    //   await this.$store
    //     .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
    //     .then(() => {
    //       this.shopInfo = this.$store.state.shop.shop;
    //       this.getOrder();
    //     })
    //     .catch(err => {
    //       throw err;
    //     });
    // },
  },
}
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.fa-edit:hover {
  color: orange;
}
</style>
