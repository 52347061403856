<template>
  <!-- Left div because b-navbar does not trigger on mouseenter / mouseleave events -->
  <div
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
    :data="backgroundColor"
  >
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <b-navbar-brand
          v-if="admin"
          style="cursor: pointer"
          @click="onLogoClick"
        >
          <img
            src="../../../public/img/brand/fortune-icon.png"
            class="navbar-brand-img"
            alt="Sidebar logo"
          />
        </b-navbar-brand>
        <b-navbar-brand style="cursor: pointer" v-else @click="onLogoClick">
          <img
            src="../../../public/img/brand/fortune-icon.png"
            class="navbar-brand-img"
            alt="Sidebar logo"
          />
        </b-navbar-brand>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div
            class="sidenav-toggler d-none d-xl-block"
            :class="{ active: !$sidebar.isMinimized }"
            @click="minimizeSidebar"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <b-navbar-nav>
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </b-navbar-nav>
        <slot name="links-after"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import jwtDecode from 'jwt-decode';
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title',
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title',
    },
    // logo: {
    //   type: String,
    //   default: '../../../public/img/brand/fortune-icon.png',
    //   description: 'Sidebar app logo',
    // },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  data() {
    return {
      admin: false,
    };
  },
  methods: {
    onLogoClick() {
      if (this.admin) {
        this.$router.push({
          path: `/dashboard/admin/admin-dashboard/shop-list`,
        });
        return;
      } else {
        this.$router.push({
          path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/product-list`,
        });
      }
    },
    checkAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      const decoded = jwtDecode(user.token);
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      };

      if (userData.roleName == 'Admin') {
        this.admin = true;
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.minimizeSidebar();
    this.checkAdmin();
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
