/*!

=========================================================
* BootstrapVue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard-pro
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import DashboardPlugin from './plugins/dashboard-plugin'
import App from './App.vue'
import store from './store/store'
import 'nprogress/nprogress.css'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

// router setup
// import router from './routes/router';
import router from './routes/starterRouter'

// plugin setup
Vue.use(DashboardPlugin)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  created() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      const decoded = jwtDecode(user.token)
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }

      this.$store.commit('user/SET_USER_DATA', userData)
      this.$store.commit('user/SET_TOKEN', user)

      this.$store
        .dispatch('user/getUserInfo', this.$store.state.user.user.id)
        .then(() => {
          if (this.$store.state.user.user.info.shopId) {
            this.$store
              .dispatch(
                'shop/getShopInfo',
                this.$store.state.user.user.info.shopId
              )
              .then(() => {})
          }
        })
        .catch(err => {
          throw err
        })
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log(error.response)
        if (error.response.status === 401) {
          this.$router.push('/')
          this.$store.dispatch('user/logOut')
        }
        return Promise.reject(error)
      }
    )
  },
})
