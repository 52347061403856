<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4"> </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <!-- Card header -->
        <b-card-header>
          <h2 slot="header" class="mb-0">
            เพิ่มสินค้าใหม่
            <p class="mb-0">โปรดกรอกข้อมูลของสินค้าของคุณ</p>
          </h2>
        </b-card-header>

        <!-- Card body -->
        <b-card-body v-if="!loading">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ชื่อสินค้า</label
                >
                <b-col md="8">
                  <base-input
                    name="name"
                    placeholder="ใส่ชื่อสินค้า"
                    rules="required"
                    v-model="product.name"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >รายละเอียดสินค้า</label
                >
                <b-col md="8">
                  <base-input
                    name="description"
                    placeholder="ใส่รายละเอียดสินค้า"
                  >
                    <textarea
                      class="form-control"
                      rows="10"
                      placeholder="ใส่รายละเอียดสินค้า..."
                      v-model="product.description"
                      resize="none"
                    ></textarea>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* หมวดหมู่</label
                >
                <b-col md="4">
                  <base-input>
                    <el-select
                      v-model="product.categoryId"
                      filterable
                      placeholder="เลือกหมวดหมู่"
                    >
                      <el-option
                        v-for="category in categories"
                        :key="category.name"
                        :label="category.name"
                        :value="category.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ราคา</label
                >
                <b-col md="2">
                  <base-input
                    name="price"
                    type="number"
                    min="0"
                    placeholder="บาท"
                    rules="required"
                    v-model.number="product.price"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* จำนวน</label
                >
                <b-col md="2">
                  <base-input
                    name="quantity"
                    type="number"
                    min="0"
                    placeholder=""
                    rules="required"
                    v-model.number="inventory.quantity"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ภาพสินค้า</label
                >
                <!-- <b-col md="3">
                  <base-input
                    name="Product Img"
                    placeholder="ใส่ url รูปภาพสินค้า"
                    rules="required"
                    v-model="product.product_image_url"
                  >
                  </base-input>
                </b-col> -->

                <!-- <b-col md="3"
                  ><input type="file" id="myFile" name="filename" />
                </b-col> -->
                <b-col md="3">
                  <dropzone-file-upload
                    v-model="img"
                    id="dzMessageCover"
                    required
                  ></dropzone-file-upload>
                </b-col>

                <!-- <b-col md="6">
                  <dropzone-file-upload
                    v-model="product.img.other"
                    id="dzMessageOther"
                    multiple
                  ></dropzone-file-upload>
                </b-col> -->
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* SKU</label
                >
                <b-col md="3">
                  <base-input
                    name="SKU"
                    placeholder="-"
                    rules="required"
                    v-model="product.SKU"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-col class="text-right">
                <base-button
                  size="md"
                  outline
                  type="default"
                  @click.native="goBack"
                  >ย้อนกลับ</base-button
                >
                <base-button type="primary" native-type="submit"
                  >บันทึก</base-button
                >
              </b-col>
            </form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
import { Dropzone } from 'dropzone';
import axios from 'axios';
import NProgress from 'nprogress';
import swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';

Dropzone.options.dzMessageCover = {
  dictDefaultMessage: '* ใส่ภาพปก',
};

Dropzone.options.dzMessageOther = {
  dictDefaultMessage: 'ใส่รูปภาพเพิ่มเติม',
};

export default {
  components: {
    DropzoneFileUpload,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      validated: false,
      product: null,
      categories: null,
      inventory: this.createFreshInventoryObject(),
      img: '',
      loading: true,
    };
  },
  created() {
    this.product = this.createFreshProductObject();
    this.inventory = this.createFreshInventoryObject();
    this.getUserInfo();
  },
  methods: {
    processImg(value) {
      value = this.img;
      const data = new FormData();
      data.append('file', value[0]);
      var config = {
        method: 'post',
        url: 'https://api-ft-production.devfinite.solutions/v1/files/upload/shop',
        data: data,
      };

      const that = this;
      axios(config)
        .then(function (response) {
          that.product.product_image_url = response.data.url;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async onSubmit() {
      // Form submit here
      if (!this.product.categoryId) {
        swal
          .fire({
            icon: 'error',
            title: 'ยังไม่มี/ไม่ได้เลือกหมวดหมู่สินค้า',
            text: 'สามารถเพิ่มหมวดหมู่สินค้าได้ที่เมนูหมวดหมู่สินค้า',
            showCancelButton: true,
            confirmButtonText: 'เพิ่มหมวดหมู่สินค้า',
            cancelButtonText: 'ปิด',
          })
          .then(value => {
            if (value.isConfirmed) {
              this.$router.push({
                name: 'Add Product Categories',
              });
            }
          });

        return;
      }

      NProgress.start();
      if (this.img !== '') {
        this.processImg();
      }
      setTimeout(async () => {
        await this.$store
          .dispatch('shop/createInventory', this.inventory)
          .then(() => {
            this.product.inventoryId =
              this.$store.state.shop.productInventory.id;
            this.product.shopId = this.$store.state.user.user.info.shopId;
          })
          .catch(async err => {
            await this.refreshErrorhandle(err);
            // this.onSubmit()
          });

        this.createProduct();
      }, 500);
    },
    createProduct() {
      this.$store
        .dispatch('shop/createProduct', this.product)
        .then(() => {
          this.$router.push({
            name: 'Product List',
          });
        })
        .catch(async err => {
          await this.refreshErrorhandle(err);
          this.createProduct();
        })
        .finally(() => NProgress.done());
    },
    filesChange(files) {
      this.inputs.file = files;
    },
    goBack(index, row) {
      this.$router.push({
        name: 'Product List',
      });
    },
    createFreshProductObject() {
      return {
        SKU: '',
        name: '',
        description: '',
        price: '',
        product_image_url: '',
        categoryId: null,
        inventoryId: '',
        shopId: '',
      };
    },
    createFreshInventoryObject() {
      return {
        quantity: '',
      };
    },
    async getUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'));
      const decoded = jwtDecode(user.token);
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      };

      await this.$store
        .dispatch('user/getUserInfo', userData.id)
        .then(() => {
          this.userInfo = this.$store.state.user.user.info;
          this.getShopInfo();
        })
        .catch(err => {
          throw err;
        });
    },
    async getShopInfo() {
      await this.$store
        .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
        .then(() => {
          this.shopInfo = this.$store.state.shop.shop;
          this.getProductCategories();
        })
        .catch(err => {
          throw err;
        });
    },
    async getProductCategories() {
      await this.$store
        .dispatch('shop/getProductCategories', this.$store.state.shop.shop.id)
        .then(responses => {
          this.categories = responses;
          this.loading = false;
        })
        .catch(error => console.log(error));
    },
    refreshErrorhandle(error) {
      const err = JSON.parse(JSON.stringify(error));
      const user = JSON.parse(localStorage.getItem('user'));
      if (err.status === 445) {
        this.$store
          .dispatch('user/refreshToken', user)
          .then(() => {
            this.$store.dispatch('shop/getNewUrl').then(() => {});
          })
          .catch(err => {
            throw err;
          });
      } else if (err.status === 446) {
        window.alert('Session Expired\nกรุณา Login ใหม่');
        this.$store.dispatch('user/logOut');
      }
    },
  },
  updated() {},
};
</script>

<style></style>
