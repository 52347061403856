<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">ร้านค้าทั้งหมด</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right" v-if="selecting === true">
          <base-button size="md" type="neutral" @click="toggleSelect"
            >ยกเลิก</base-button
          >
          <base-button size="md" type="danger" @click="handleDelete"
            >ลบ {{ selectedRows.length }} รายการที่เลือก</base-button
          >
        </b-col>

        <b-col lg="6" cols="5" class="text-right" v-else>
          <base-button size="md" type="neutral" @click="toggleSelect"
            >เลือกรายการ</base-button
          >
          <!-- <base-button size="md" type="default" @click="addShop"
            >เพิ่มร้านค้า</base-button
          > -->
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">รายชื่อร้านค้า</h3>
            <p class="text-sm mb-0">รายชื่อร้านค้าทั้งหมดใน Fortune Town</p>
          </template>
          <div>
            <b-col
              cols="12"
              class="d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search Shop..."
                >
                </base-input>
              </div>
            </b-col>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              v-if="!loading"
            >
              <el-table-column v-if="selecting" type="selection" />

              <el-table-column
                label="ชื่อร้านค้า"
                prop="name"
                min-width="180px"
                sortable="true"
                key="ชื่อร้านค้า"
              >
                <template v-slot="{ row }">
                  <a
                    @click="handleView(index, row)"
                    class="font-weight-bold hover-cursor"
                    >{{ row.name }}</a
                  >
                </template>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column
                min-width="180px"
                align="center"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="table-actions">
                  <!-- <el-tooltip content="ดูร้านค้า" placement="top">
                    <a
                      href="#!"
                      @click.prevent="handleView($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="View Shop"
                    >
                      <i class="fas fa-globe"></i>
                    </a> </el-tooltip
                  >&nbsp; -->
                  <el-tooltip content="ดูสินค้าของร้าน" placement="top">
                    <a
                      href="#!"
                      @click.prevent="handleViewProducts($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="View Product"
                    >
                      <i class="fas fa-clipboard-list"></i>
                    </a> </el-tooltip
                  >&nbsp;
                  <el-tooltip content="แก้ไขร้านค้า" placement="top">
                    <a
                      href="#!"
                      @click.prevent="handleEdit($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="Edit Shop"
                    >
                      <i class="fas fa-edit"></i>
                    </a> </el-tooltip
                  >&nbsp;
                  <el-tooltip
                    v-if="selecting !== true"
                    content="ลบร้านค้า"
                    placement="top"
                  >
                    <a
                      href="#!"
                      @click.prevent="handleDelete($index, row)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      data-original-title="Delete Shop"
                    >
                      <i class="fas fa-trash"></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>

              <!-- <el-table-column
                min-width="180px"
                align="right"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="d-flex">
                  <base-button
                    @click.native="handleView($index, row)"
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-like-2"></i>
                    <i class="text-white ni ni-single-copy-04"></i>
                  </base-button>
                  <base-button
                    @click.native="handleViewProducts($index, row)"
                    class="like btn-link"
                    type="success"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-like-2"></i>
                    <i class="text-white ni ni-bag-17"></i>
                  </base-button>
                  <base-button
                    @click.native="handleEdit($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-ruler-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete($index, row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-fat-remove"></i>
                  </base-button>
                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; เลือก {{ selectedRows.length }} รายการ
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Tooltip } from 'element-ui'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components'
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin'
import swal from 'sweetalert2'
// import users from './users2'

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    this.getShop()
  },
  data() {
    return {
      loading: true,
      selecting: false,
      searchQuery: '',
      propsToSearch: ['name', 'category'],
      tableColumns: [
        {
          prop: 'category',
          label: 'หมวดหมู่ร้านค้า',
          minWidth: 120,
          sortable: true,
        },
        {
          prop: 'address',
          label: 'ตำแหน่งร้านค้า',
          minWidth: 180,
          sortable: true,
        },
        {
          prop: 'users[0].first_name',
          label: 'ผู้ดูแลร้านค้า',
          minWidth: 120,
          // sortable: true,
        },
      ],
      tableData: [],
      selectedRows: [],
    }
  },
  methods: {
    async getShop() {
      await this.$store.dispatch('admin/getShops').then(() => {
        this.tableData = this.$store.state.admin.shops
        this.getShopCategories()
      })
    },
    async getShopCategories() {
      await this.$store
        .dispatch('shop/getShopCategories')
        .then(responses => {
          this.tableData.forEach(shop => {
            responses.forEach(res => {
              if (shop.categoryId === res.id) {
                shop.category = res.name
              }
            })
          })
          this.loading = false
        })
        .catch(error => console.log(error))
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    toggleSelect() {
      this.selecting = !this.selecting
    },
    createShop() {
      return {
        name: 'string',
        description: 'string',
        profile_image_url: 'string',
        categoryId: 1,
        phoneNumber: 'string',
        address: 'string',
        lineId: 'string',
        fbName: 'string',
        website: 'string',
      }
    },
    // addShop() {
    //   swal
    //     .fire({
    //       title: 'เพิ่มร้านค้า',
    //       html: `<input
    //       type="text"
    //       class="swal2-input"
    //       placeholder="ชื่อร้านค้า"
    //       id="shop-name"
    //       >
    //       `,
    //       showCancelButton: true,
    //       confirmButtonClass: 'btn btn-success btn-fill',
    //       cancelButtonClass: 'btn btn-danger btn-fill',
    //       confirmButtonText: 'เพิ่มร้านค้า',
    //       cancelButtonText: 'ยกเลิก',
    //       preConfirm: () => {
    //         const shopName = swal.getPopup().querySelector('#shop-name').value
    //         if (!shopName) {
    //           swal.showValidationMessage(`ยังไม่ได้กรอกชื่อร้านค้า!`)
    //         }

    //         return {
    //           name: shopName,
    //           description: '',
    //           profile_image_url: '',
    //           categoryId: null,
    //           phoneNumber: '',
    //           address: '',
    //           lineId: '',
    //           fbName: '',
    //           website: '',
    //         }
    //       },
    //     })
    //     .then(result => {
    //       this.postShop(result.value)
    //       swal.fire(
    //         `
    //         เพิ่มร้าน ${result.value.name} แล้ว
    //         `.trim()
    //       )
    //     })
    // },
    // postShop(shop) {
    //   this.$store.dispatch('admin/createShop', shop)
    // },
    handleViewProducts(index, row) {
      this.$router.push({
        path: `/dashboard/admin/admin-dashboard/shops/${row.id}/products`,
      })
    },
    handleView(index, row) {
      window.open(
        `https://catalogue.fortunetown.co.th/shops/${row.id}`,
        '_blank'
      )
    },
    handleEdit(index, row) {
      localStorage.setItem('editShop', JSON.stringify(row))
      this.$router.push({
        path: `/dashboard/admin/admin-dashboard/edit-shop/${row.id}`,
      })
    },
    handleDelete(index, row) {
      if (this.selecting === true && this.selectedRows.length === 0) {
        swal.fire({
          title: 'คุณยังไม่ได้เลือกรายการ',
          buttonsStyling: false,
          type: 'warning',
          confirmButtonClass: 'btn btn-warning btn-fill',
        })
        return
      }

      swal
        .fire({
          title: 'คุณแน่ใจใช่ไหม?',
          text: `คุณจะไม่สามารถย้อนกลับได้!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'ลบร้านค้า',
          cancelButtonText: 'ยกเลิก',
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            if (this.selectedRows.length > 1) {
              this.selectedRows.forEach(row => this.deleteRow(row))
              this.toggleSelect()

              swal.fire({
                title: `ลบร้านทั้ง ${this.selectedRows.length} ร้านแล้ว`,
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false,
              })
            } else {
              this.deleteRow(row)
              swal.fire({
                title: `ลบร้าน ${row.name} แล้ว`,
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false,
              })
            }
          }
        })
    },
    async deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      )
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }

      await this.$store
        .dispatch('admin/deleteShop', row.id)
        .then()
        .catch(err => {
          throw err
        })
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
  },
}
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.fa-edit:hover {
  color: orange;
}

.fa-globe:hover {
  color: skyblue;
}

.fa-clipboard-list:hover {
  color: rgb(108, 221, 125);
}

.fas {
  font-size: 1.2rem;
}

.el-table .cell {
  text-align: left;
}
</style>
