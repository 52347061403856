<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
      <b-container class="container">
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">ลงทะเบียนกับ Fortune Town</h1>
              <p class="text-lead text-white">
                เพิ่มโอกาสใหม่ๆให้กับร้านค้าของคุณ
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <card>
        <b-row align-v="center" slot="header">
          <b-col cols="8">
            <h3 class="heading-h3 mb-0">กรุณากรอกข้อมูลของคุณ</h3>
          </b-col>
        </b-row>

        <b-form @submit.prevent="onSubmit">
          <h6 class="heading-small text-muted mb-4">ข้อมูลส่วนตัว</h6>

          <!-- User Info -->
          <div class="pl-lg-4">
            <b-row>
              <b-col lg="6">
                <base-input
                  type="text"
                  label="* ชื่อจริง"
                  placeholder="ชื่อจริง"
                  v-model="userInfo.first_name"
                  :rules="{ required: true }"
                  :custom-messages="FirstNameMessages"
                >
                </base-input>
              </b-col>
              <b-col lg="6">
                <base-input
                  type="text"
                  label="* นามสกุล"
                  placeholder="นามสกุล"
                  v-model="userInfo.last_name"
                  :rules="{ required: true }"
                  :custom-messages="LastNameMessages"
                >
                </base-input>
              </b-col>
            </b-row>
          </div>
          <hr class="my-4" />

          <!-- Shop Info -->
          <h6 class="heading-small text-muted mb-4">ข้อมูลร้านค้า</h6>

          <div class="pl-lg-4">
            <b-row>
              <b-col md="12">
                <base-input
                  type="text"
                  label="* ชื่อร้าน"
                  placeholder="ชื่อร้าน"
                  v-model="shop.name"
                  :rules="{ required: true }"
                  :custom-messages="ShopNameMessages"
                >
                </base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <base-input label="* หมวดหมู่ร้าน">
                  <el-select
                    v-model="shop.categoryId"
                    filterable
                    placeholder="หมวดหมู่ร้าน"
                  >
                    <el-option
                      v-for="category in categories"
                      :key="category.name"
                      :label="category.name"
                      :value="category.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col lg="6">
                <base-input
                  label="* เบอร์โทรศัพท์"
                  placeholder="เบอร์"
                  v-model="shop.phoneNumber"
                >
                </base-input>
              </b-col>

              <b-col md="12">
                <base-input
                  label="* ที่อยู่ร้านค้า (ระบุชั้นและเลขที่ห้องใน Fortune Town)"
                  placeholder="ที่อยู่ร้านค้า"
                  v-model="shop.address"
                >
                </base-input>
              </b-col>

              <b-col lg="6">
                <base-input
                  label="Facebook ร้านค้า"
                  placeholder="Facebook ของร้าน"
                  v-model="shop.fbName"
                >
                </base-input>
              </b-col>

              <b-col lg="6">
                <base-input
                  label="Line ID ร้านค้า"
                  placeholder="Line ID ของร้าน"
                  v-model="shop.lineId"
                >
                </base-input>
              </b-col>

              <b-col md="12">
                <base-input
                  label="เว็บไซต์ร้านค้า"
                  placeholder="เว็บไซต์ร้านค้า"
                  v-model="shop.website"
                >
                </base-input>
              </b-col>

              <b-col md="12">
                <base-input
                  type="text"
                  label="รายละเอียดร้าน"
                  placeholder="รายละเอียดร้าน"
                >
                  <textarea
                    class="form-control"
                    rows="10"
                    placeholder="ใส่รายละเอียดสินค้า..."
                    resize="none"
                    v-model="shop.description"
                  ></textarea>
                </base-input>
              </b-col>
            </b-row>

            <label class="col-md-2 col-form-label form-control-label"
              >* ภาพปกร้าน</label
            >
            <b-row>
              <!-- <b-col md="12">
                <base-input
                  label="รูปภาพร้าน"
                  placeholder="url รูปภาพร้าน"
                  v-model="shop.profile_image_url"
                >
                </base-input>
              </b-col> -->
              <b-col md="3">
                <dropzone-file-upload
                  v-model="img"
                  id="dzMessageCover"
                  required
                ></dropzone-file-upload>
              </b-col>
            </b-row>
          </div>

          <b-col class="text-right">
            <base-button
              size="md"
              outline
              type="default"
              @click.native="logout"
              class="mt-5"
              style="font-size: 1rem"
              >ออกจากระบบ</base-button
            >
            <base-button
              type="primary"
              native-type="submit"
              variant="primary"
              class="mt-5"
              style="font-size: 1rem"
              >บันทึก</base-button
            >
          </b-col>
        </b-form>
      </card>
    </b-container>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import axios from 'axios';
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
import { Dropzone } from 'dropzone';
import NProgress from 'nprogress';
import Service from '@/services/Service';
import { mapState } from 'vuex';

Dropzone.options.dzMessageCover = {
  dictDefaultMessage: '* ใส่ภาพ',
};

export default {
  name: 'register',
  components: {
    DropzoneFileUpload,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      userInfo: this.createFreshUserInfoObject(),
      img: [],
      shop: this.createFreshShopObject(),
      categories: [],
      FirstNameMessages: {
        required: 'กรุณากรอกชื่อจริง',
      },
      LastNameMessages: {
        required: 'กรุณากรอกนามสกุล',
      },
      ShopNameMessages: {
        required: 'กรุณากรอกชื่อร้าน',
      },
      ShopCatMessages: {
        required: 'กรุณาเลือกหมวดหมู่ร้าน',
      },
    };
  },
  computed: {
    // ...mapState(['shop', 'user']),
  },
  created() {
    this.$store.state.shop.apiClientAuth = axios.create({
      baseURL: 'https://api-ft-production.devfinite.solutions',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // prettier-ignore
        'Authorization': localStorage.getItem('user') ? `Bearer ${JSON.parse(localStorage.getItem('user')).token}` : '',
      },
    });
    this.getShopCategories();
    if (this.$store.state.user.user.info) {
      this.userInfo = this.$store.state.user.user.info;
    }
  },
  mounted() {},
  methods: {
    processImg(value) {
      value = this.img;
      const data = new FormData();
      data.append('file', value[0]);
      var config = {
        method: 'post',
        url: 'https://api-ft-production.devfinite.solutions/v1/files/upload/profile',
        data: data,
      };

      const that = this;
      axios(config)
        .then(function (response) {
          that.shop.profile_image_url = response.data.url;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async onSubmit() {
      NProgress.start();
      if (this.img !== '') {
        this.processImg();
      }
      setTimeout(async () => {
        await this.$store
          .dispatch('shop/createShop', this.shop)
          .then(() => {
            this.userInfo.shopId = this.$store.state.shop.shop.id;
            this.shop = this.createFreshShopObject();
          })
          .catch(error => {
            throw new Error(error);
          });

        // console.log(this.userInfo)

        await this.$store
          .dispatch('user/addUserInfo', this.userInfo)
          .then(() => {
            this.userInfo = this.createFreshUserInfoObject();
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => NProgress.done());

        this.$router.push({
          path: `/dashboard/${this.$store.state.user.user.id}/${this.userInfo.shopId}/product-list`,
        });
      }, 500);
    },
    async getShopCategories() {
      await this.$store
        .dispatch('shop/getShopCategories')
        .then(responses => {
          responses.forEach(res => this.categories.push(res));
        })
        .catch(error => console.log(error));
    },
    logout() {
      this.$store.dispatch('user/logOut');
    },
    createFreshUserInfoObject() {
      return {
        first_name: '',
        last_name: '',
        shopId: null,
        userId: this.$store.state.user.user.id,
      };
    },
    createFreshShopObject() {
      return {
        name: '',
        description: '',
        profile_image_url: '',
        categoryId: null,
        phoneNumber: '',
        address: '',
        lineId: '',
        fbName: '',
        website: '',
      };
    },
  },
};
</script>
<style>
.heading-h3 {
  font-size: 1.2rem;
}

.heading-small {
  font-size: 0.9rem;
}
</style>
