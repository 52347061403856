import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '../views/Layout/DashboardLayout.vue'
import VerifyPage from '../views/Pages/Verify.vue'
import LoginPage from '../views/Pages/Login.vue'
// import OrderList from '../views/Tables/OrderTable.vue'
import ProductList from '../views/Tables/ProductTable.vue'
import AdminProductList from '../views/Tables/AdminTable/ProductTable.vue'
import AddProduct from '../views/Forms/AddProduct.vue'
import EditProduct from '../views/Forms/EditProduct.vue'
import CreateProfile from '../views/Pages/CreateProfile.vue'
import EditProfile from '../views/Pages/EditProfile.vue'
import ViewOrder from '../views/Pages/ViewOrder.vue'
import ShopList from '../views/Tables/ShopTable.vue'
import EditShop from '../views/Pages/EditShop.vue'
import EventTable from '../views/Tables/EventTable.vue'
import PromotionTable from '../views/Tables/PromotionTable.vue'
import PromotionEachShop from '../views/Tables/PromotionTableEachShop.vue'
import ProductsPromotion from '../views/Tables/ProductsPromotions.vue'
import ProductDiscount from '../views/Tables/ProductDiscount.vue'
import ProductDiscountCheck from '../views/Tables/ProductDiscountCheck.vue'
import AddPromotion from '../views/Forms/AddPromotion.vue'
import EditPromotion from '../views/Forms/EditPromotion.vue'
import AddEvent from '../views/Forms/AddEvent.vue'
import EditEvent from '../views/Forms/EditEvent.vue'
import AddProductCategories from '../views/Tables/ProductCategoriesTable.vue'
import NotFound from '../views/GeneralViews/NotFoundPage.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/shop-verify/:userId',
      name: 'VerifyPage',
      component: VerifyPage,
    },
    // {
    //   path: '/verify',
    //   redirect: to => {
    //     return { path: '/shop-verify' }
    //   },
    // },
    {
      path: '/create-profile/:userId',
      name: 'CreateProfile',
      component: CreateProfile,
      meta: { requiresAuth: true },
    },
    {
      path: '/shop-login',
      name: 'LoginPage',
      component: LoginPage,
    },
    {
      path: '/',
      redirect: to => {
        return { path: '/shop-login' }
      },
    },
    {
      path: '/login',
      redirect: to => {
        return { path: '/shop-login' }
      },
    },
    {
      path: '/dashboard/:UserId/:ShopId',
      name: 'Dashboard',
      redirect: '/dashboard/:UserId/:ShopId/product-list',
      component: DashboardLayout,
      meta: { requiresAuth: true },
      children: [
        // {
        //   path: 'order-list',
        //   name: 'Order List',
        //   component: OrderList,
        // },
        {
          path: 'order/:id',
          name: 'Order',
          props: true,
          component: ViewOrder,
        },
        {
          path: 'product-list',
          name: 'Product List',
          component: ProductList,
        },
        {
          path: 'add-product',
          name: 'AddProduct',
          component: AddProduct,
        },
        {
          path: 'edit-product/:productSKU',
          name: 'EditProduct',
          component: EditProduct,
        },
        {
          path: 'edit-profile',
          name: 'EditProfile',
          component: EditProfile,
        },
        {
          path: 'shop-list',
          name: 'Shop List',
          component: ShopList,
        },
        {
          path: 'edit-shop/:shopId',
          name: 'EditShop',
          component: EditShop,
        },
        {
          path: 'event-list',
          name: 'Event List',
          component: EventTable,
        },
        {
          path: 'add-event',
          name: 'AddEvent',
          component: AddEvent,
        },
        {
          path: 'edit-event/:eventId',
          name: 'EditEvent',
          component: EditEvent,
        },
        {
          path: 'promotion-lists',
          name: 'Promotion Lists',
          component: PromotionTable,
        },
        {
          path: 'promotion-list',
          name: 'Promotion List',
          component: PromotionEachShop,
        },
        {
          path: 'discount-list',
          name: 'Discount List',
          component: ProductDiscount,
        },
        {
          path: 'discount-list/:discountId',
          name: 'Discount CheckBox List',
          component: ProductDiscountCheck,
        },
        {
          path: 'products-promotion/:promotionId',
          name: 'Promotion CheckBox List',
          component: ProductsPromotion,
        },
        {
          path: 'add-promotion',
          name: 'AddPromotion',
          component: AddPromotion,
        },
        {
          path: 'edit-promotion/:promotionId',
          name: 'EditPromotion',
          component: EditPromotion,
        },
        {
          path: 'add-product-categories',
          name: 'Add Product Categories',
          component: AddProductCategories,
        },
        {
          path: 'shops/:shopId/products',
          name: 'Shop Products',
          component: AdminProductList,
        },
        {
          path: 'admin-product-list',
          name: 'Admin Products List',
          component: AdminProductList,
        },
      ],
    },
    {
      path: '/:catchAll(.*)',
      name: 'NotFoound',
      component: NotFound,
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const loggedIn = await localStorage.getItem('user')

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/')
  }
  next()
})

export default router
