import axios from 'axios';

export const apiClient = axios.create({
  baseURL: 'https://api-ft-production.devfinite.solutions',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  postUser(user) {
    return apiClient.post('v1/users', user);
  },
  postAuthLogin(user) {
    return apiClient.post('v1/auth/login', user);
  },
  postAuthRefresh(token) {
    return apiClient.post('v1/auth/refresh', token);
  },
  postUserInfo(userInfo) {
    return apiClient.post('v1/user-info', userInfo);
  },
  getUserInfo() {
    return apiClient.get('v1/user-info');
  },
  editUserInfo(userInfo) {
    return apiClient.patch(`v1/user-info/${userInfo.id}`, {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      shopId: userInfo.shopId,
      userId: userInfo.userId,
    });
  },
  getUserRoles() {
    return apiClient.get('v1/user-roles');
  },
  getShop() {
    return apiClient.get('v1/shops');
  },
  getShopById(shopId) {
    return apiClient.get(`v1/shops/${shopId}`);
  },
  getProducts() {
    return apiClient.get(`v1/products`);
  },
  getProductByShop(shopId) {
    return apiClient.get(`v1/products?shopId=${shopId}`);
  },
  getProductById(productId) {
    return apiClient.get(`v1/products/${productId}`);
  },
  getShopCategory() {
    return apiClient.get('v1/shop-categories');
  },
  getProductCategory() {
    return apiClient.get('v1/product-categories');
  },
  getProductCategoryByShop(shopId) {
    return apiClient.get(`v1/product-categories?shopId=${shopId}`);
  },
  getOrder(shopId) {
    return apiClient.get(`v1/orders/?shopId=${shopId}`);
  },
  getOrderItems() {
    return apiClient.get('v1/order-items');
  },
  editOrder(order) {
    return apiClient.patch(`v1/orders/${order.id}`, {
      customerId: order.customerId,
      total: order.total,
      trackingNumber: order.trackingNumber,
      paymentId: order.paymentId,
    });
  },
  uploadFile(file) {
    return apiClient.post(`v1/files/upload/test`, file);
  },
  getEvent() {
    return apiClient.get('v1/events');
  },
  getPromotion() {
    return apiClient.get('v1/promotions');
  },
  getPromotionByShop(shopId) {
    return apiClient.get(`v1/promotions?shopId=${shopId}`);
  },
  getProductsPromotion(promotionId) {
    return apiClient.get(`v1/promotions/${promotionId}/products`);
  },
  notify(message) {
    return apiClient.post(`/v1/line/notify`, message);
  },
};
