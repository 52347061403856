<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4"> </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <!-- Card header -->
        <b-card-header>
          <h2 slot="header" class="mb-0">
            เพิ่มอีเว้นท์ใหม่
            <p class="mb-0">กรอกรายละเอียดของอีเว้นท์</p>
          </h2>
        </b-card-header>

        <!-- Card body -->
        <b-card-body v-if="!loading">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ชื่ออีเว้นท์</label
                >
                <b-col md="8">
                  <base-input
                    name="name"
                    placeholder="ใส่ชื่ออีเว้นท์"
                    rules="required"
                    v-model="event.name"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >รายละเอียดอีเว้นท์</label
                >
                <b-col md="8">
                  <base-input name="description">
                    <textarea
                      class="form-control"
                      rows="10"
                      placeholder="ใส่รายละเอียดอีเว้นท์"
                      v-model="event.description"
                      resize="none"
                    ></textarea>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >Link อีเว้นท์</label
                >
                <b-col md="8">
                  <base-input
                    name="name"
                    placeholder="ใส่ link อีเว้นท์"
                    v-model="event.link"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <!-- <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* หมวดหมู่</label
                >
                <b-col md="4">
                  <base-input>
                    <el-select
                      v-model="product.categoryId"
                      filterable
                      placeholder="เลือกหมวดหมู่"
                    >
                      <el-option
                        v-for="category in categories"
                        :key="category.name"
                        :label="category.name"
                        :value="category.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
              </b-row> -->

              <!-- <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* วันที่เริ่มอีเว้นท์</label
                >
                <b-col md="3">
                  <base-input
                    name="starting date"
                    type="number"
                    min="0"
                    rules="required"
                    v-model="event.startDate"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* วันที่สิ้นสุดอีเว้นท์</label
                >
                <b-col md="3">
                  <base-input
                    name="ending date"
                    type="number"
                    min="0"
                    placeholder=""
                    rules="required"
                    v-model="event.endDate"
                  >
                  </base-input>
                </b-col>
              </b-row> -->

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ระยะเวลาอีเว้นท์</label
                >
                <b-col md="3">
                  <base-input>
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{
                        allowInput: true,
                        enableTime: true,
                        time_24hr: true,
                      }"
                      class="form-control datepicker"
                      v-model="startDate"
                      placeholder="วันที่เริ่มอีเว้นท์"
                    >
                    </flat-picker>
                  </base-input>
                </b-col>
                <b-col md="3">
                  <base-input>
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{
                        allowInput: true,
                        enableTime: true,
                        time_24hr: true,
                      }"
                      class="form-control datepicker"
                      v-model="endDate"
                      placeholder="วันที่สิ้นสุดอีเว้นท์"
                    >
                    </flat-picker>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ภาพปกอีเว้นท์</label
                >
                <!-- <b-col md="3">
                  <base-input
                    name="Product Img"
                    placeholder="ใส่ url รูปภาพสินค้า"
                    rules="required"
                    v-model="product.product_image_url"
                  >
                  </base-input>
                </b-col> -->

                <!-- <b-col md="3"
                  ><input type="file" id="myFile" name="filename" />
                </b-col> -->
                <b-col md="5">
                  <dropzone-file-upload
                    v-model="img"
                    id="dzMessageCover"
                    required
                  ></dropzone-file-upload>
                </b-col>

                <!-- <b-col md="6">
                  <dropzone-file-upload
                    v-model="product.img.other"
                    id="dzMessageOther"
                    multiple
                  ></dropzone-file-upload>
                </b-col> -->
              </b-row>

              <!-- <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* SKU</label
                >
                <b-col md="3">
                  <base-input
                    name="SKU"
                    placeholder="-"
                    rules="required"
                    v-model="product.SKU"
                  >
                  </base-input>
                </b-col>
              </b-row> -->

              <b-col class="text-right">
                <base-button
                  size="md"
                  outline
                  type="default"
                  @click.native="goBack"
                  >ย้อนกลับ</base-button
                >
                <base-button type="primary" native-type="submit"
                  >บันทึก</base-button
                >
              </b-col>
            </form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
import { Dropzone } from 'dropzone';
import axios from 'axios';
import NProgress from 'nprogress';
import flatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

Dropzone.options.dzMessageCover = {
  dictDefaultMessage: '* ใส่ภาพปก',
};

Dropzone.options.dzMessageOther = {
  dictDefaultMessage: 'ใส่รูปภาพเพิ่มเติม',
};

export default {
  components: {
    DropzoneFileUpload,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
  },
  data() {
    return {
      validated: false,
      event: null,
      img: '',
      loading: true,
      startDate: null,
      endDate: null,
    };
  },
  created() {
    this.event = this.createFreshEventObject();
    this.loading = false;
  },
  methods: {
    processImg(value) {
      value = this.img;
      const data = new FormData();
      data.append('file', value[0]);
      var config = {
        method: 'post',
        url: 'https://api-ft-production.devfinite.solutions/v1/files/upload/event',
        data: data,
      };

      const that = this;
      axios(config)
        .then(function (response) {
          that.event.event_img_url = response.data.url;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async onSubmit() {
      // Form submit here
      NProgress.start();
      if (this.img !== '') {
        this.processImg();
      }
      setTimeout(async () => {
        this.event.startDate = this.convertToISO(this.startDate);
        this.event.endDate = this.convertToISO(this.endDate);
        await this.$store
          .dispatch('admin/createEvent', this.event)
          .then(() => {
            this.$router.push({
              name: 'Event List',
            });
          })
          .catch(err => {})
          .finally(() => NProgress.done());
      }, 500);
    },
    convertToISO(selectedDate) {
      const date = new Date(selectedDate);
      return date.toISOString();
    },
    createEvent() {
      this.$store
        .dispatch('admin/createEvent', this.product)
        .then(() => {
          this.$router.push({
            name: 'Product List',
          });
        })
        .catch(async err => {
          await this.refreshErrorhandle(err);
          this.createProduct();
        })
        .finally(() => NProgress.done());
    },
    filesChange(files) {
      this.inputs.file = files;
    },
    goBack(index, row) {
      this.$router.push({ name: 'Event List' });
    },
    createFreshEventObject() {
      return {
        name: '',
        description: '',
        event_img_url: '',
        link: '',
        status: 'Ongoing',
        endDate: '',
        startDate: '',
      };
    },
    refreshErrorhandle(error) {
      const err = JSON.parse(JSON.stringify(error));
      const user = JSON.parse(localStorage.getItem('user'));
      if (err.status === 445) {
        this.$store
          .dispatch('user/refreshToken', user)
          .then(() => {
            this.$store.dispatch('shop/getNewUrl').then(() => {});
          })
          .catch(err => {
            throw err;
          });
      } else if (err.status === 446) {
        window.alert('Session Expired\nกรุณา Login ใหม่');
        this.$store.dispatch('user/logOut');
      }
    },
  },
  updated() {},
};
</script>

<style></style>
