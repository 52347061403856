<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4"> </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <!-- Card header -->
        <b-card-header>
          <h2 slot="header" class="mb-0">
            แก้ไขอีเว้นท์
            <p class="mb-0">แก้ไขรายละเอียดของอีเว้นท์</p>
          </h2>
        </b-card-header>

        <!-- Card body -->
        <b-card-body v-if="!loading">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ชื่ออีเว้นท์</label
                >
                <b-col md="8">
                  <base-input
                    name="name"
                    placeholder="ใส่ชื่ออีเว้นท์"
                    rules="required"
                    v-model="event.name"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >รายละเอียดอีเว้นท์</label
                >
                <b-col md="8">
                  <base-input name="description">
                    <textarea
                      class="form-control"
                      rows="10"
                      placeholder="ใส่รายละเอียดอีเว้นท์"
                      v-model="event.description"
                      resize="none"
                    ></textarea>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >Link อีเว้นท์</label
                >
                <b-col md="8">
                  <base-input
                    name="name"
                    placeholder="ใส่ link อีเว้นท์"
                    v-model="event.link"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ระยะเวลาอีเว้นท์</label
                >
                <b-col md="3">
                  <base-input>
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{
                        allowInput: true,
                        enableTime: true,
                        time_24hr: true,
                      }"
                      class="form-control datepicker"
                      v-model="startDate"
                      placeholder="วันที่เริ่มอีเว้นท์"
                    >
                    </flat-picker>
                  </base-input>
                </b-col>
                <b-col md="3">
                  <base-input>
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{
                        allowInput: true,
                        enableTime: true,
                        time_24hr: true,
                      }"
                      class="form-control datepicker"
                      v-model="endDate"
                      placeholder="วันที่สิ้นสุดอีเว้นท์"
                    >
                    </flat-picker>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ภาพปกอีเว้นท์</label
                >
                <b-col md="3" v-if="event.event_img_url">
                  <div class="img-container">
                    <img v-bind:src="event.event_img_url" alt="" class="" />
                  </div>
                </b-col>

                <b-col md="3">
                  <dropzone-file-upload
                    v-model="img"
                    id="dzMessageCover"
                    required
                  ></dropzone-file-upload>
                </b-col>
              </b-row>

              <base-button
                size="md"
                outline
                type="default"
                @click.native="goBack"
                >ย้อนกลับ</base-button
              >
              <base-button type="primary" native-type="submit"
                >บันทึก</base-button
              >
            </form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
import { Dropzone } from 'dropzone'
import axios from 'axios'
import NProgress from 'nprogress'
import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

Dropzone.options.dzMessageCover = {
  dictDefaultMessage: '* ใส่ภาพปก',
}

Dropzone.options.dzMessageOther = {
  dictDefaultMessage: 'ใส่รูปภาพเพิ่มเติม',
}

export default {
  components: {
    DropzoneFileUpload,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
  },
  data() {
    return {
      validated: false,
      event: null,
      img: '',
      loading: true,
      startDate: null,
      endDate: null,
    }
  },
  created() {
    this.event = JSON.parse(localStorage.getItem('EditEvent'))
    this.startDate = this.getDateTime(this.event.startDate)
    this.endDate = this.getDateTime(this.event.endDate)
    this.loading = false
  },
  methods: {
    getDateTime(date) {
      let newDate = new Date(date)
      return newDate
    },
    processImg(value) {
      value = this.img
      const data = new FormData()
      data.append('file', value[0])
      var config = {
        method: 'post',
        url: 'https://api-ft-production.devfinite.solutions/v1/files/upload/event',
        data: data,
      }

      const that = this
      axios(config)
        .then(function (response) {
          that.event.event_img_url = response.data.url
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async onSubmit() {
      // Form submit here
      NProgress.start()
      if (this.img) {
        this.processImg()
      }
      setTimeout(async () => {
        let eventObj = {}
        eventObj.startDate = this.convertToISO(this.startDate)
        eventObj.endDate = this.convertToISO(this.endDate)
        eventObj.name = this.event.name
        eventObj.description = this.event.description
        eventObj.link = this.event.link
        eventObj.event_img_url = this.event.event_img_url
        eventObj.status = this.event.status
        this.event.startDate = this.convertToISO(this.startDate)
        this.event.endDate = this.convertToISO(this.endDate)
        await this.$store
          .dispatch('admin/editEvent', { id: this.event.id, event: eventObj })
          .then(() => {
            this.$router.push({
              name: 'Event List',
            })
          })
          .catch(err => {
            refreshErrorhandle(err)
          })
          .finally(() => NProgress.done())
      }, 500)
    },
    convertToISO(selectedDate) {
      const date = new Date(selectedDate)
      return date.toISOString()
    },
    filesChange(files) {
      this.inputs.file = files
    },
    goBack(index, row) {
      this.$router.push({ name: 'Event List' })
    },
    refreshErrorhandle(error) {
      const err = JSON.parse(JSON.stringify(error))
      const user = JSON.parse(localStorage.getItem('user'))
      if (err.status === 445) {
        this.$store
          .dispatch('user/refreshToken', user)
          .then(() => {
            this.$store.dispatch('shop/getNewUrl').then(() => {})
          })
          .catch(err => {
            throw err
          })
      } else if (err.status === 446) {
        window.alert('Session Expired\nกรุณา Login ใหม่')
        this.$store.dispatch('user/logOut')
      }
    },
  },
  updated() {},
}
</script>

<style></style>
