<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6 border-bottom">
      <b-row class="justify-content-center">
        <b-col lg="8" class="card-wrapper">
          <card v-if="!loading">
            <b-row align-v="center" slot="header">
              <b-col class="ml--1">
                <h3 slot="header" class="mb-0">
                  หมายเลขคำสั่งซื้อ #{{ order.id }}
                </h3>
              </b-col>

              <!-- FOR FUTURE -->
              <!-- <b-col md="auto">
                <div class="tracking-container">
                  <base-input
                    name="Tracking Number"
                    placeholder="ใส่เลข tracking number"
                    v-model="trackingNumber"
                    class="form-group-here"
                  >
                  </base-input>
                  <base-button native-type="submit" type="primary" size="sm"
                    >Add</base-button
                  >
                </div>
              </b-col> -->
            </b-row>

            <form @submit.prevent="addTrackingNumber">
              <div class="order-list">
                <b-row
                  v-for="order in order.order_items"
                  :key="order.productId"
                  align-v="center"
                  class="content--center each-row"
                >
                  <b-col md="auto">
                    <!-- Avatar -->
                    <a href="#" class="avatar avatar-xl rounded-circle">
                      <img
                        alt="Image placeholder"
                        v-bind:src="order.product.product_image_url"
                      />
                    </a>
                  </b-col>
                  <b-col class="ml--2">
                    <h4 class="mb-0">
                      <a href="#!">{{ order.product.name }}</a>
                    </h4>
                    <p class="text-sm text-muted mb-0">
                      {{ order.product.description }}
                    </p>
                    <span>x{{ order.quantity }}</span>
                  </b-col>
                  <b-col md="auto">
                    <p class="mb-0">
                      ฿{{ order.product.price * order.quantity }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </form>
            <b-row class="price-container">
              <b-col class="text-right">ยอดคำสั่งซื้อทั้งหมด</b-col>
              <b-col md="auto"
                ><strong class="total-price">฿{{ order.total }}</strong></b-col
              >
            </b-row>
          </card>

          <card v-else>
            <b-row align-v="center" slot="header">
              <b-col class="ml--1">
                <h3 slot="header" class="mb-0">หมายเลขคำสั่งซื้อ #</h3>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import NProgress from 'nprogress'

export default {
  data() {
    return {
      order: null,
      products: null,
      trackingNumber: '',
      loading: true,
    }
  },
  async mounted() {
    NProgress.start()
    await this.getProductById()

    setTimeout(() => {
      this.loading = false
      NProgress.done()
    }, 1000)
  },
  methods: {
    // addTrackingNumber() {
    //   if (this.order.trackingNumber !== '') {
    //     this.order.trackingNumber = this.trackingNumber
    //     this.$store
    //       .dispatch('order/editOrder', this.order)
    //       .then(() => {})
    //       .catch(err => {
    //         throw err
    //       })
    //   }
    // },
    getProductById() {
      this.order = this.$store.state.order.selectedOrder
      const orderItems = this.order.order_items.forEach(order => {
        order.product = []
        this.$store
          .dispatch('shop/getProductById', order.productId)
          .then(() => {
            order.product = this.$store.state.shop.productInOrder
          })
      })
    },
  },
  updated() {},
}
</script>
<style>
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0;
  margin: auto;
  flex-wrap: nowrap;
}

.header-text {
  width: 100%;
}

.tracking-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.tracking-container base-button {
  gap: 1rem;
}

.border-bottom .card-body .order-list {
  margin-bottom: 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.form-group-here fieldset.form-group {
  margin-bottom: 0 !important;
}

.each-row {
  margin-bottom: 1rem;
}

.price-container {
  align-items: center;
  justify-content: center;
}

.total-price {
  font-size: 1.4rem;
}

.content-right {
  justify-self: flex-end;
}
</style>
