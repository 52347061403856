<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">
            รายการหมวดหมู่สินค้า
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="7">
          <b-card no-body>
            <!-- Card header -->
            <b-card-header>
              <h3 class="mb-0">เพิ่มหมวดหมู่สินค้า</h3>
            </b-card-header>
            <!-- Card body -->
            <b-card-body>
              <form ref="productCategory">
                <base-input
                  label="ชื่อหมวดหมู่"
                  placeholder="กรอกหมวดหมู่..."
                  v-model="productCategory.name"
                />

                <base-input label="รายละเอียดหมวดหมู่">
                  <textarea
                    placeholder="กรอกรายละเอียด..."
                    class="form-control"
                    id="exampleFormControlTextarea3"
                    rows="5"
                    v-model="productCategory.description"
                  ></textarea>
                </base-input>
              </form>

              <b-col class="text-right">
                <base-button
                  size="md"
                  type="primary"
                  @click="createProductCategories"
                  >เพิ่มหมวดหมู่สินค้า</base-button
                >
                <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
              </b-col>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col xl="5">
          <div>
            <card
              class="no-border-card"
              body-classes="px-0 pb-1"
              footer-classes="pb-2"
            >
              <template slot="header" class="header--container">
                <h3 class="mb-0">หมวดหมู่สินค้าของคุณ</h3>
                <p class="text-sm mb-0">เพิ่มและแก้ไขหมวดหมู่สินค้าทั้งหมด</p>
              </template>

              <b-col
                cols="12"
                class="d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <!-- <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select> -->

                <div class="btn-search-container">
                  <base-input
                    v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Search Product Categories..."
                  >
                  </base-input>
                </div>
              </b-col>
              <el-table
                :data="queriedData"
                row-key="id"
                header-row-class-name="thead-light"
                @sort-change="sortChange"
                @selection-change="selectionChange"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  v-bind="column"
                >
                </el-table-column>
                <el-table-column
                  min-width="180px"
                  align="right"
                  label="ดำเนินการ"
                >
                  <div slot-scope="{ $index, row }" class="d-flex">
                    <el-tooltip content="ลบหมวดหมู่" placement="top">
                      <a
                        href="#!"
                        @click.prevent="handleDelete($index, row)"
                        class="table-action table-action-delete"
                        data-toggle="tooltip"
                        data-original-title="Delete Shop"
                      >
                        <i class="fas fa-trash"></i>
                      </a>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>

              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ

                    <span v-if="selectedRows.length">
                      &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                    </span>
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                  @change="paginationChanged($event)"
                >
                </base-pagination>
              </div>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import { BasePagination } from '@/components';
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin';
import swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';
// import users from './users2';

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      propsToSearch: ['name'],
      tableColumns: [
        // {
        //   type: 'selection',
        // },
        {
          prop: 'name',
          label: 'ชื่อหมวดหมู่สินค้า',
          minWidth: 170,
          sortable: true,
        },
        // {
        //   prop: 'SKU',
        //   label: 'SKU',
        //   minWidth: 150,
        //   sortable: true,
        // },
        // {
        //   prop: 'price',
        //   label: 'ราคา',
        //   minWidth: 100,
        //   sortable: true,
        // },
        // {
        //   prop: 'inventory.quantity',
        //   label: 'คลัง',
        //   minWidth: 100,
        //   sortable: true,
        // },
      ],
      tableData: [],
      selectedRows: [],
      productCategory: null,
      productCategories: [],
    };
  },
  created() {
    this.getUserInfo();
    this.productCategory = this.createFreshProductCategoryObject();
  },
  methods: {
    // async getProductByShop() {
    //   await this.$store.dispatch(
    //     'shop/getProductByShop',
    //     this.$store.state.user.user.info.shopId
    //   )

    //   this.tableData = this.$store.state.shop.shop.products
    // },
    async createProductCategories() {
      this.productCategory.shopId = this.$store.state.shop.shop.id;
      await this.$store.dispatch(
        'shop/createProductCategory',
        this.productCategory
      );
      this.$refs.productCategory.reset();
    },
    async getProductCategoriesByShop() {
      await this.$store
        .dispatch('shop/getProductCategories', this.$store.state.shop.shop.id)
        .then(() => {
          this.tableData = this.$store.state.shop.productCategories;
        });
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    handleEdit(index, row) {
      swal.fire({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill',
      });
      // this.$store.state.shop.editProduct = row
      // localStorage.setItem('editProduct', JSON.stringify(row))
      // this.$router.push({
      //   name: 'EditProduct',
      // })
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: 'คุณแน่ใจใช่ไหม?',
          text: `คุณจะไม่สามารถย้อนกลับได้!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'ลบหมวดหมู่',
          cancelButtonText: 'ยกเลิก',
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            this.deleteRow(row);
            swal.fire({
              title: 'ลบหมวดหมู่เรียบร้อย',
              text: `ลบหมวดหมู่ ${row.name} แล้ว`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false,
            });
          }
        });
    },
    async deleteRow(row) {
      await this.$store
        .dispatch('shop/deleteProductCategory', row.id)
        .then(() => {})
        .catch(err => {
          throw err;
        });
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    createFreshProductCategoryObject() {
      return {
        name: '',
        description: '',
        shopId: null,
      };
    },
    async getUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'));
      const decoded = jwtDecode(user.token);
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      };

      await this.$store
        .dispatch('user/getUserInfo', userData.id)
        .then(() => {
          this.userInfo = this.$store.state.user.user.info;
          this.getShopInfo();
        })
        .catch(err => {
          throw err;
        });
    },
    async getShopInfo() {
      await this.$store
        .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
        .then(() => {
          this.shopInfo = this.$store.state.shop.shop;
          // this.getProductByShop()
          this.getProductCategoriesByShop();
        })
        .catch(err => {
          throw err;
        });
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.btn-search-container {
  display: flex;
}

.btn-addproduct {
  align-self: flex-start;
  margin-right: 1.5rem !important;
}
</style>
