<template>
  <footer class="footer px-4 mx-2">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col lg="6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="https://www.devfinite.solutions"
            class="font-weight-bold ml-1"
            target="_blank"
            >DevFinite</a
          >
        </div>
      </b-col>
      <b-col lg="6">
        <b-nav align="center" class="nav-footer justify-content-lg-end">
          <b-nav-item href="https://www.devfinite.solutions" target="_blank">
            DevFinite
          </b-nav-item>
          <b-nav-item
            href="https://www.devfinite.solutions/about-us"
            target="_blank"
          >
            About Us
          </b-nav-item>
          <b-nav-item href="http://blog.devfinite.solutions/" target="_blank">
            Blog
          </b-nav-item>
          <b-nav-item
            href="https://www.devfinite.solutions/contact-us"
            target="_blank"
          >
            Contact us
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
