import Service from '../../services/Service';
import axios from 'axios';
import store from '../store';

const apiClientAuth = axios.create({
  baseURL: 'https://api-ft-production.devfinite.solutions',
  withCredentials: false,
});

apiClientAuth.interceptors.request.use(
  async config => {
    const value = await JSON.parse(localStorage.getItem('user'));
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // prettier-ignore
      'Authorization': localStorage.getItem('user') ? `Bearer ${value.token}` : '',
    };
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

apiClientAuth.interceptors.response.use(
  response => response,
  async error => {
    const err = JSON.parse(JSON.stringify(error));
    const user = JSON.parse(localStorage.getItem('user'));
    if (err.status === 445) {
      await store
        .dispatch('user/refreshToken', user)
        .then(() => {
          store.dispatch('shop/getNewUrl').then(() => {});
        })
        .catch(err => {
          throw err;
        });
    } else if (err.status === 446) {
      window.alert('Session Expired\nกรุณา Login ใหม่');
      store.dispatch('user/logOut');
    }
  }
);

export const namespaced = true;

export const state = {
  apiClientAuth: null,
  shop: null,
  shopCategories: null,
  productCategories: null,
  productInventory: null,
  editProduct: null,
  productInOrder: null,
  shopList: null,
  promotions: null,
  productPromotion: null,
  productDiscounts: null,
};

export const mutations = {
  SET_SHOP(state, shop) {
    state.shop = shop;
  },
  SET_SHOP_LIST(state, shopList) {
    state.shopList = shopList;
  },
  SET_SHOP_CATEGORIES(state, shopCat) {
    state.shopCategories = shopCat;
  },
  SET_PRODUCT_CATEGORIES(state, productCat) {
    state.productCategories = productCat;
  },
  ADD_PRODUCT_CATEGORY(state, productCat) {
    if (!state.productCategories) {
      state.productCategories = [];
    }
    state.productCategories.push(productCat);
  },
  SET_PRODUCT_INVENTORY(state, productInv) {
    state.productInventory = productInv;
  },
  SET_PRODUCTS(state, products) {
    state.shop.products = products;
  },
  SET_PRODUCT_IN_ORDER(state, product) {
    state.productInOrder = product;
  },
  SET_PROMOTIONS(state, promotions) {
    state.promotions = promotions;
  },
  SET_PRODUCT_PROMOTION(state, productPromotion) {
    state.productPromotion = productPromotion;
  },
  SET_PRODUCT_DISCOUNT(state, productDiscount) {
    state.productDiscounts = productDiscount;
  },
  ADD_PRODUCT_DISCOUNT(state, productDiscount) {
    if (!state.productDiscount) {
      state.productDiscounts = [];
    }
    state.productDiscounts.push(productDiscount);
  },
  ADD_PROMOTION(state, promotion) {
    if (!state.promotions) {
      state.promotions = [];
    }
    state.promotions.push(promotion);
  },
  ADD_PRODUCT(state, product) {
    if (!state.shop.products) {
      state.shop.products = [];
    }
    state.shop.products.push(product);
  },
  EDIT_PRODUCT(state, product) {
    if (state.editProduct) {
      return
    }

    const index = state.shop.products.findIndex(
      eachProduct => eachProduct.id === product.id
    );
    state.shop.products[index] = product;
  },
  EDIT_INVENTORY(state, inventory) {
    state.productInventory = inventory;
  },
  EDIT_PRODUCT_DISCOUNT(state, productDiscount) {
    const index = state.productDiscounts.findIndex(
      eachDiscount => eachDiscount.id === productDiscount.id
    );

    state.productDiscounts[index] = productDiscount;
  },
  DELETE_PRODUCT(state, productId) {
    const index = state.shop.products.findIndex(
      eachProduct => eachProduct.id === productId
    );

    state.shop.products.splice(index, 1);
  },
  DELETE_PRODUCT_CATEGORY(state, productCatId) {
    const index = state.productCategories.findIndex(
      eachProductCat => eachProductCat.id === productCatId
    );

    state.productCategories.splice(index, 1);
  },
  DELETE_promotion(state, promotion) {
    const index = state.promotions.findIndex(
      eachPromotion => eachPromotion.id === promotion.id
    );

    state.promotions.splice(index, 1);
  },
  DELETE_PRODUCT_PROMOTION(state, productPromotion) {
    const index = state.productPromotion.findIndex(
      eachProductPromotion => eachProductPromotion.id === productPromotion.id
    );

    state.productPromotion.splice(index);
  },
  DELETE_PRODUCT_DISCOUNT(state, productDiscount) {
    const newProductDiscounts = state.productDiscounts.filter(
      (eachDiscount, index) =>
        eachDiscount.id !== state.productDiscounts[index].id
    );

    state.productDiscounts = newProductDiscounts;
  },
  CLEAR_PRODUCT_IN_ORDER(state) {
    state.productInOrder = null;
  },
  CLEAR_INVENTORY(state) {
    state.productInventory = null;
  },
};

export const actions = {
  async createShop({ commit }, shop) {
    try {
      const { data } = await apiClientAuth.post('v1/shops', shop);
      commit('SET_SHOP', data);
    } catch (err) {
      // const error = JSON.parse(JSON.stringify(err))
      // if (err.status === 445) {
      //   store.dispatch('user/refreshToken')
      // } else if (err.status === 446) {
      //   window.alert('Session Expired\nกรุณา Login ใหม่')
      //   store.dispatch('user/logOut')
      // }
      throw err;
    }
  },
  async createInventory({ commit }, inventory) {
    try {
      const { data } = await apiClientAuth.post(
        'v1/product-inventories',
        inventory
      );
      commit('SET_PRODUCT_INVENTORY', data);
    } catch (err) {
      throw err;
    }
  },
  async createProduct({ commit }, product) {
    try {
      const { data } = await apiClientAuth.post('v1/products', product);
      commit('ADD_PRODUCT', data);
    } catch (err) {
      throw err;
    }
  },
  async createProductCategory({ commit }, productCategory) {
    try {
      const { data } = await apiClientAuth.post(
        'v1/product-categories',
        productCategory
      );
      commit('ADD_PRODUCT_CATEGORY', data);
    } catch (err) {
      throw err;
    }
  },
  async createPromotion({ commit }, promotion) {
    try {
      const { data } = await apiClientAuth.post('v1/promotions', promotion);
      //await apiClientAuth.post("v1/line/notify", {message:"ร้านเพิ่ม Promotion เข้ามาใหม่!! \n สถานะ:กำลังรอการ Approve/Decline"});
      commit('ADD_PROMOTION', data);
    } catch (err) {
      throw err;
    }
  },
  async getProductDiscount({ commit }, shopId) {
    const { data } = await apiClientAuth.get(
      `v1/product-discount?shopId=${shopId}`
    );

    commit('SET_PRODUCT_DISCOUNT', data.results);
  },
  async createProductDiscount({ commit }, productDiscount) {
    const { data } = await apiClientAuth.post(
      'v1/product-discount',
      productDiscount
    );

    commit('ADD_PRODUCT_DISCOUNT', data);
  },
  async getShopList({ commit }) {
    try {
      const { data } = await Service.getShop();
      const allShop = data.results;
      commit('SET_SHOP_LIST', allShop);
    } catch (err) {
      throw err;
    }
  },
  async getShopInfo({ commit }, shopId) {
    try {
      const { data } = await Service.getShop();
      const allShop = data.results;
      const shopInfo = allShop.filter(shop => shop.id === shopId);
      commit('SET_SHOP', shopInfo[0]);
    } catch (err) {
      throw err;
    }
  },
  async getShopCategories({ commit }) {
    try {
      const { data } = await Service.getShopCategory();
      commit('SET_SHOP_CATEGORIES', data.results);
      return data.results;
    } catch (err) {
      throw err;
    }
  },
  async getProductCategories({ commit }, shopId) {
    try {
      const { data } = await Service.getProductCategoryByShop(shopId);
      commit('SET_PRODUCT_CATEGORIES', data.results);
      return data.results;
    } catch (err) {
      throw err;
    }
  },
  async getProductByShop({ commit }, shopId) {
    try {
      const { data } = await Service.getProductByShop(shopId);
      commit('SET_PRODUCTS', data.results);
    } catch (err) {
      throw err;
    }
  },
  async getProductById({ commit }, productId) {
    const { data } = await Service.getProductById(productId);
    commit('SET_PRODUCT_IN_ORDER', data);
  },
  async getPromotionsByShop({ commit }, shopId) {
    try {
      const { data } = await Service.getPromotionByShop(shopId);
      commit('SET_PROMOTIONS', data.results);
    } catch (err) {
      throw err;
    }
  },
  async postProductPromotion({ commit }, productId) {
    const promotionId = JSON.parse(
      localStorage.getItem('selectedPromotion')
    ).id;
    const { data } = await apiClientAuth.post('v1/product-promotion', {
      productId: productId,
      promotionId: promotionId,
    });
    commit('SET_PRODUCT_PROMOTION', data);
  },
  async deletePromotion({ commit }, promotionId) {
    await apiClientAuth.delete(`v1/promotions/${promotionId}`);
    //commit("DELETE_promotion");
  },
  async deleteProductPromotion({ commit }, obj) {
    const promise = new Promise((resolve, reject) => {
      apiClientAuth
        .get(
          `v1/product-promotion?productId=${obj.productId}&promotionId=${obj.promotionId}`
        )
        .then(res => {
          resolve(res.data.results);
        })
        .catch(err => {
          reject(err);
        });
    });
    promise
      .then(res => {
        res.forEach(item => {
          apiClientAuth.delete(`v1/product-promotion/${item.id}`);
        });
      })
      .catch(err => {
        console.log(err);
      });
  },
  async deleteProductCategory({ commit }, productCategoryId) {
    const { data } = await apiClientAuth.delete(
      `v1/product-categories/${productCategoryId}`
    );
    commit('DELETE_PRODUCT_CATEGORY', productCategoryId);
  },
  async editShopInfo({ commit }, shop) {
    const { data } = await apiClientAuth.patch(`v1/shops/${shop.id}`, {
      name: shop.name,
      description: shop.description,
      profile_image_url: shop.profile_image_url,
      categoryId: shop.categoryId,
      phoneNumber: shop.phoneNumber,
      address: shop.address,
    });
    commit('SET_SHOP', data);
  },
  async editProduct({ commit }, product) {
    const { data } = await apiClientAuth.patch(`v1/products/${product.id}`, {
      SKU: product.SKU,
      name: product.name,
      description: product.description,
      price: product.price,
      product_image_url: product.product_image_url,
      categoryId: product.categoryId,
      inventoryId: product.inventoryId,
      shopId: product.shopId,
      discountId: product.discountId,
    });
    commit('EDIT_PRODUCT', data);
  },
  async editInventory({ commit }, inventory) {
    const { data } = await apiClientAuth.patch(
      `v1/product-inventories/${inventory.id}`,
      {
        quantity: inventory.quantity,
      }
    );
    commit('EDIT_INVENTORY', data);
  },
  async editPromotion({}, obj) {
    try {
      const { data } = await apiClientAuth.patch(
        `v1/promotions/${obj.id}`,
        obj.obj
      );
    } catch (err) {
      throw err;
    }
  },
  async editProductDiscount({ commit }, productDiscount) {
    const { data } = await apiClientAuth.patch(
      `v1/product-discount/${productDiscount.id}`,
      productDiscount
    );

    commit('EDIT_PRODUCT_DISCOUNT', data);
  },
  async deleteProduct({ commit }, productId) {
    await apiClientAuth.delete(`v1/products/${productId}`);
    commit('DELETE_PRODUCT', productId);
  },

  async deleteInventory({ commit }, inventoryId) {
    await apiClientAuth.delete(`v1/product-inventories/${inventoryId}`);
    commit('CLEAR_INVENTORY', inventoryId);
  },

  async deleteProductDiscount({ commit }, productDiscountId) {
    await apiClientAuth.delete(`v1/product-discount/${productDiscountId}`);
    commit('DELETE_PRODUCT_DISCOUNT', productDiscountId);
  },

  clearProductInOrder({ commit }) {
    commit('CLEAR_PRODUCT_IN_ORDER');
  },
  getNewUrl() {
    apiClientAuth = axios.create({
      baseURL: 'https://api-ft-staging.devfinite.solutions/',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // prettier-ignore
        'Authorization': localStorage.getItem('user') ? `Bearer ${JSON.parse(localStorage.getItem('user')).token}` : '',
      },
    });
  },
};
