<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-info navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <!-- <b-form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <b-form-group class="mb-0">
        <b-input-group class="input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <b-form-input placeholder="Search" type="text"> </b-form-input>
        </b-input-group>
      </b-form-group>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </b-form> -->

    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
      <base-dropdown
        class="nav-item"
        tag="li"
        title-classes="nav-link"
        title-tag="a"
        icon="ni ni-bell-55"
        menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
      >
        <template>
          <!-- Dropdown header -->
          <div class="px-3 py-3">
            <h6 class="text-sm text-muted m-0">
              You have <strong class="text-primary">0</strong> notifications.
            </h6>
          </div>
          <!-- <a
            href="#!"
            class="dropdown-item text-center text-primary font-weight-bold py-3"
            >View all</a
          > -->
        </template>
      </base-dropdown>
      <!-- <base-dropdown
        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="ni ni-ungroup"
      >
        <template>
          <b-row class="shortcuts px-4">
            <a href="#!" class="col-4 shortcut-item">
              <span
                class="shortcut-media avatar rounded-circle bg-gradient-red"
              >
                <i class="ni ni-calendar-grid-58"></i>
              </span>
              <small>Calendar</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
              <span
                class="shortcut-media avatar rounded-circle bg-gradient-orange"
              >
                <i class="ni ni-email-83"></i>
              </span>
              <small>Email</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
              <span
                class="shortcut-media avatar rounded-circle bg-gradient-info"
              >
                <i class="ni ni-credit-card"></i>
              </span>
              <small>Payments</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
              <span
                class="shortcut-media avatar rounded-circle bg-gradient-green"
              >
                <i class="ni ni-books"></i>
              </span>
              <small>Reports</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
              <span
                class="shortcut-media avatar rounded-circle bg-gradient-purple"
              >
                <i class="ni ni-pin-3"></i>
              </span>
              <small>Maps</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
              <span
                class="shortcut-media avatar rounded-circle bg-gradient-yellow"
              >
                <i class="ni ni-basket"></i>
              </span>
              <small>Shop</small>
            </a>
          </b-row>
        </template>
      </base-dropdown> -->
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <!-- <img alt="Image placeholder" src="/img/theme/team-4.jpg" /> -->
              <img
                v-if="!loading"
                alt="Image placeholder"
                src="../../../public/img/brand/botnoi.png"
                class="profile-img"
              />
              <img
                v-else-if="shopInfo"
                alt="Image placeholder"
                v-bind:src="shopInfo.profile_image_url"
                class="profile-img"
              />
              <img
                v-else-if="admin"
                alt="Image placeholder"
                src="../../../public/img/brand/botnoi.png"
                class="profile-img"
              />
            </span>
            <b-media-body v-if="userInfo" class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold"
                >{{ userInfo.first_name }} {{ userInfo.last_name }}</span
              >
            </b-media-body>
            <b-media-body v-else-if="admin" class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">Admin</span>
            </b-media-body>
          </b-media>
        </a>

        <template>
          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </b-dropdown-header>

          <b-dropdown-item @click="toEditProfile" v-if="!admin">
            <i class="ni ni-settings-gear-65"></i>
            <span>แก้ไขโปรไฟล์</span>
          </b-dropdown-item>

          <!-- <b-dropdown-item href="#!">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </b-dropdown-item>
          <b-dropdown-item href="#!">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </b-dropdown-item> -->
          <div class="dropdown-divider"></div>
          <b-dropdown-item @click="logout">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components'
import jwtDecode from 'jwt-decode'

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description:
        'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      userInfo: null,
      shopInfo: null,
      loading: true,
      admin: false,
    }
  },
  async mounted() {
    this.checkAdmin()
    if (!this.admin) await this.getUserInfo()
  },
  methods: {
    toEditProfile() {
      this.$router.push({
        path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/edit-profile`,
      })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    logout() {
      this.$store.dispatch('user/logOut')
    },
    async getUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'))
      const decoded = jwtDecode(user.token)
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }

      await this.$store
        .dispatch('user/getUserInfo', userData.id)
        .then(() => {
          this.userInfo = this.$store.state.user.user.info
          this.getShopInfo()
        })
        .catch(err => {
          throw err
        })
    },
    async getShopInfo() {
      await this.$store
        .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
        .then(() => {
          this.shopInfo = this.$store.state.shop.shop
          this.loading = true
        })
        .catch(err => {
          throw err
        })
    },
    checkAdmin() {
      const user = JSON.parse(localStorage.getItem('user'))
      const decoded = jwtDecode(user.token)
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }

      if (userData.roleName == 'Admin') {
        this.admin = true
      }
    },
  },
  created() {},
  updated() {},
}
</script>

<style>
.profile-img {
  height: 36px;
  object-fit: cover;
}
</style>
