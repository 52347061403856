var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[(_vm.admin)?_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'รายชื่อร้านค้า',
          path: '/dashboard/admin/admin-dashboard/shop-list',
          icon: 'ni ni-shop text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'รายการสินค้า',
          path: '/dashboard/admin/admin-dashboard/admin-product-list',
          icon: 'ni ni-cart text-default',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'อีเว้นท์',
          path: '/dashboard/admin/admin-dashboard/event-list',
          icon: 'ni ni-bell-55 text-yellow',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'โปรโมชั่น',
          path: '/dashboard/admin/admin-dashboard/promotion-lists',
          icon: 'ni ni-notification-70 text-info',
        }}})],1):_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'รายการสินค้า',
          icon: 'ni ni-shop text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'สินค้าทั้งหมด',
            path: ("/dashboard/" + (this.$route.params.UserId) + "/" + (this.$route.params.ShopId) + "/product-list"),
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'หมวดหมู่สินค้า',
            path: ("/dashboard/" + (this.$route.params.UserId) + "/" + (this.$route.params.ShopId) + "/add-product-categories"),
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'ส่วนลด',
          path: ("/dashboard/" + (this.$route.params.UserId) + "/" + (this.$route.params.ShopId) + "/discount-list"),
          icon: 'fa fa-percent text-success',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'โปรโมชั่น',
          path: ("/dashboard/" + (this.$route.params.UserId) + "/" + (this.$route.params.ShopId) + "/promotion-list"),
          icon: 'ni ni-notification-70 text-info',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'แก้ไขโปรไฟล์',
          path: ("/dashboard/" + (this.$route.params.UserId) + "/" + (this.$route.params.ShopId) + "/edit-profile"),
          icon: 'ni ni-settings-gear-65 text-orange',
        }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('b-nav',{staticClass:"navbar-nav mb-md-3"},[_c('b-nav-item',{attrs:{"href":"https://ft-ecommerce.devfinite.solutions"}},[_c('i',{staticClass:"ni ni-spaceship"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("หน้าเว็บ Fortune Town")])],1)],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }