<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
      <b-container class="container">
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="7" lg="6" md="8" class="px-5">
              <h1 class="text-white">เปลี่ยนรหัสผ่านเพื่อยืนยันบัญชีของคุณ</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <!-- Table -->
      <b-row class="justify-content-center">
        <b-col lg="6" md="8">
          <b-card no-body class="bg-secondary border-0">
            <!-- <b-card-header class="bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
              <div class="text-center">
                <a href="#" class="btn btn-neutral btn-icon mr-4">
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/github.svg"
                  /></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/google.svg"
                  /></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>กรุณาเปลี่ยนรหัสผ่านเพื่อยืนยันบัญชีของคุณ</small>
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <!-- <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-email-83"
                    placeholder="อีเมล"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    :custom-messages="EmailMessages"
                    v-model="user.username"
                  >
                  </base-input> -->

                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-lock-circle-open"
                    placeholder="รหัสผ่าน"
                    ref="password"
                    type="password"
                    name="password"
                    :rules="{ required: true, min: 6 }"
                    :custom-messages="PasswordMessages"
                    v-model="userData.password"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-lock-circle-open"
                    placeholder="ยืนยันรหัสผ่าน"
                    type="password"
                    name="ConfirmPassword"
                    :rules="{
                      required: true,
                      min: 6,
                      confirmed: 'password',
                    }"
                    :custom-messages="ConfirmPasswordMessages"
                    v-model="confirmPassword"
                  >
                  </base-input>
                  <!-- <div class="text-muted font-italic">
                    <small
                      >password strength:
                      <span class="text-success font-weight-700"
                        >strong</span
                      ></small
                    >
                  </div> -->

                  <!-- <b-row class="my-4">
                    <b-col cols="12">
                      <base-input
                        :rules="{ required: { allowFalse: false } }"
                        :custom-messages="PolicyMessages"
                        name="Privacy"
                        Policy
                      >
                        <b-form-checkbox v-model="agree">
                          <span class="text-muted"
                            >ฉันยอมรับเงื่อนไขและข้อตกลง
                            <a href="#!">นโยบายความเป็นส่วนตัว</a></span
                          >
                        </b-form-checkbox>
                      </base-input>
                      <p class="error-message">
                        {{ EmailMessages.error }}
                      </p>
                    </b-col>
                  </b-row> -->

                  <div class="text-center">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mt-4 signup-btn"
                      >เปลี่ยนรหัสผ่าน</b-button
                    >
                    <!-- <p class="already-have-account">
                      มีบัญชีกับ Fortune Town อยู่แล้ว?
                      <router-link to="shop-login"
                        >เข้าสู่ระบบที่นี่</router-link
                      >
                    </p> -->
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import NProgress from 'nprogress'
import jwtDecode from 'jwt-decode'
import { apiClient } from '../../services/Service'

export default {
  name: 'register',
  data() {
    return {
      confirmPassword: '',
      agree: false,
      userData: {},
      // EmailMessages: {
      //   required: 'กรุณากรอกอีเมล',
      //   email: 'กรุณากรอกอีเมลให้ถูกต้อง',
      //   error: null,
      // },
      PasswordMessages: {
        required: 'กรุณากรอกรหัสผ่าน',
        min: 'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
      },
      ConfirmPasswordMessages: {
        required: 'กรุณากรอกยืนยันรหัสผ่าน',
        min: 'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
        confirmed: 'รหัสผ่านไม่ตรงกัน',
      },
      // PolicyMessages: {
      //   required: 'กรุณายอมรับข้อตกลงและนโยบาลความเป็นส่วนตัว',
      // },
    }
  },
  created() {
    this.decodeToken()

    if (!this.$store.state.user.user.token) {
      this.$router.push({
        name: 'LoginPage',
      })
    }
  },
  methods: {
    async onSubmit() {
      NProgress.start()

      await apiClient
        .patch(`v1/users/${this.userData.id}`, {
          password: this.userData.password,
          isVerified: true,
        })
        .then(() => {
          if (this.userData.roleName === 'Admin') {
            this.$router.push({
              path: '/dashboard/admin/admin-dashboard/shop-list',
            })

            return
          }

          this.$router.push({
            name: 'CreateProfile',
          })
        })
        .catch(err => {
          throw err
        })
        .finally(() => NProgress.done())
    },
    decodeToken() {
      const user = JSON.parse(localStorage.getItem('user'))
      const decoded = jwtDecode(user.token)
      this.userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }
    },
  },
}
</script>
<style>
.btn-outline {
  background-color: #fff;
  color: #5e72e4;
  margin-left: 1rem;
}

.btn-outline:hover {
  background-color: #eee;
  color: #5e72e4;
}

.signup-btn {
  margin-top: 20px !important;
  margin-bottom: 1rem;
  width: 100%;
}

.already-have-account {
  font-size: 0.9rem;
}

.error-message {
  font-size: 0.9rem;
  color: red;
}
</style>
