<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center justify-content-sm-between flex-wrap mb--3 mt-2"
        >
          <h3 class="mb-0 mt-2">เลือกสินค้าเข้าร่วมส่วนลด</h3>
          <div class="btn-search-container">
            <base-input
              v-model="searchQuery"
              prepend-icon="fas fa-search"
              placeholder="Search Product..."
            >
            </base-input>
          </div>
        </b-col>
      </b-row>
    </b-card-header>

    <el-table
      v-if="filterTableData"
      ref="tableSelectProduct"
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      row-key="id"
      :data="filterTableData"
      @selection-change="onSelectionChange"
    >
      <el-table-column type="selection" align="left" min-width="120px">
      </el-table-column>

      <el-table-column
        label="ชื่อสินค้า"
        min-width="200px"
        prop="name"
        sortable
      >
        <template v-slot="{ row }">
          <b>{{ row.name }}</b>
        </template>
      </el-table-column>

      <el-table-column
        label="SKU"
        prop="SKU"
        width="200px"
        min-width="140px"
        sortable
      >
      </el-table-column>

      <el-table-column
        label="ราคา"
        prop="price"
        width="170px"
        min-width="140px"
        sortable
      >
      </el-table-column>

      <el-table-column
        label="จำนวน"
        prop="inventory.quantity"
        width="170px"
        min-width="140px"
        sortable
      >
      </el-table-column>
    </el-table>
  </b-card>
</template>
<script>
// import users from './../users'
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from 'element-ui'
export default {
  name: 'inline-actions-table',
  props: {
    tableProductData: [],
    selected: [],
  },
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  created() {},
  data() {
    return {
      currentPage: 1,
      selectedRows: [],
      searchQuery: '',
      filterTableData: [],
    }
  },
  watch: {
    tableProductData(newVal, oldVal) {
      if (newVal.length != null) {
        this.filterTableData = this.tableProductData
        this.initTable()
      }
    },
    searchQuery(newVal, oldVal) {
      this.filterTableData = this.tableProductData.filter(
        data =>
          !newVal || data.name.toLowerCase().includes(newVal.toLowerCase())
      )
      this.initTable()
    },
  },
  computed: {},
  methods: {
    onSelectionChange(selectedRows) {
      this.selectedRows = selectedRows
      this.$emit('selectedProducts', this.selectedRows)
    },
    initTable() {
      this.$nextTick(() => {
        let table = this.$refs.tableSelectProduct
        //tableproduct โชว์สินค้าทั้งหมด
        //รับมาแค่สินค้าที่อยู่ในโปรโม
        //ต้อง toggle เฉพาะอันที่ id ตรงกัน
        this.filterTableData.forEach((row, index) => {
          if (this.selected.some(x => x.id == row.id)) {
            table.toggleRowSelection(this.filterTableData[index], true)
          }
        })
      })
    },
  },
}
</script>
