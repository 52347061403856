<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">รายการสินค้า</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right"> </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-if="shopInfo" slot="header" class="header--container">
            <h3 class="mb-0">สินค้าของร้าน {{ shopInfo.name }}</h3>
            <h3 class="mb-0">เลขที่ร้าน : {{ shopInfo.id }}</h3>
            <p class="text-sm mb-0">รายการสินค้าและแก้ไขสินค้า</p>
          </template>
          <template v-else slot="header" class="header--container">
            <h3 class="mb-0">สินค้าทั้งหมด</h3>
          </template>

          <div>
            <b-col
              cols="12"
              class="d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div class="btn-search-container">
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search Product..."
                >
                </base-input>
              </div>
            </b-col>

            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
            >
              <el-table-column
                label="ชื่อสินค้า"
                prop="name"
                min-width="330px"
                sortable="true"
                key="ชื่อสินค้า"
              >
                <template v-slot="{ $index, row }">
                  <a
                    @click="handleView($index, row)"
                    class="font-weight-bold hover-cursor"
                    >{{ row.name }}</a
                  >
                </template>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column min-width="180px" align="center">
                <div slot-scope="{ $index, row }" class="table-actions">
                  <!-- <el-tooltip content="ดูสินค้า" placement="top">
                    <a
                      href="#!"
                      @click.prevent="handleView($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="View Product"
                    >
                      <i class="fas fa-globe"></i>
                    </a> </el-tooltip
                  >&nbsp; -->
                  <el-tooltip content="แก้ไขสินค้า" placement="top">
                    <a
                      href="#!"
                      @click.prevent="handleEdit($index, row)"
                      class="table-action"
                      data-toggle="tooltip"
                      data-original-title="Edit Product"
                    >
                      <i class="fas fa-edit"></i>
                    </a> </el-tooltip
                  >&nbsp;
                  <el-tooltip content="ลบสินค้า" placement="top">
                    <a
                      href="#!"
                      @click.prevent="handleDelete($index, row)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      data-original-title="Delete Product"
                    >
                      <i class="fas fa-trash"></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>

              <!-- <el-table-column
                min-width="180px"
                align="right"
                label="ดำเนินการ"
              >
                <div slot-scope="{ $index, row }" class="d-flex">
                  <base-button
                    @click.native="handleView($index, row)"
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-world-2"></i>
                  </base-button>
                  <base-button
                    @click.native="handleEdit($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-ruler-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete($index, row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-fat-remove"></i>
                  </base-button>
                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components'
import clientPaginationMixin from '../PaginatedTables/clientPaginationMixin'
import swal from 'sweetalert2'
import jwtDecode from 'jwt-decode'
import api from '@/services/Service'
// import users from './users2';

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      propsToSearch: ['name', 'SKU'],
      tableColumns: [
        {
          prop: 'shop.name',
          label: 'ชื่อร้าน',
          minWidth: 150,
          sortable: true,
        },
        {
          prop: 'price',
          label: 'ราคา',
          minWidth: 100,
          sortable: true,
        },
        {
          prop: 'inventory.quantity',
          label: 'คลัง',
          minWidth: 100,
          sortable: true,
        },
      ],
      tableData: [],
      selectedRows: [],
      shopInfo: {},
    }
  },
  created() {
    if (!this.checkAdmin()) {
      this.$router.push({ path: '/dashboard' })
    }
    this.getProductByShop()
    this.getShopInfo()
  },
  methods: {
    checkAdmin() {
      const user = JSON.parse(localStorage.getItem('user'))
      const decoded = jwtDecode(user.token)
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      }

      if (userData.roleName == 'Admin') {
        return true
      } else return false
    },

    async getProductByShop() {
      if (this.$route.params.shopId) {
        api.getProductByShop(this.$route.params.shopId).then(res => {
          this.tableData = res.data.results
        })
      } else {
        api.getProducts().then(res => {
          this.tableData = res.data.results
        })
      }
    },
    async getShopInfo() {
      if (this.$route.params.shopId) {
        api.getShopById(this.$route.params.shopId).then(res => {
          this.shopInfo = res.data
        })
      } else this.shopInfo = ''
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    handleView(index, row) {
      window.open(
        `https://catalogue.fortunetown.co.th/shops/${row.shopId}/products/${row.id}`,
        '_blank'
      )
    },
    handleEdit(index, row) {
      this.$store.state.shop.editProduct = row
      localStorage.setItem('editProduct', JSON.stringify(row))
      this.$router.push({
        path: `/dashboard/admin/admin-dashboard/edit-product/${row.SKU}`,
      })
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: 'คุณแน่ใจใช่ไหม?',
          text: `คุณจะไม่สามารถย้อนกลับได้!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'ลบสินค้า',
          cancelButtonText: 'ยกเลิก',
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            this.deleteRow(row)
            swal.fire({
              title: 'Deleted!',
              text: `You deleted ${row.name}`,
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false,
            })
          }
        })
    },
    async deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      )
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }

      await this.$store
        .dispatch('shop/deleteProduct', row.id)
        .then(() => {
          this.$store
            .dispatch('shop/deleteInventory', row.inventoryId)
            .then(() => {
              this.getProductByShop()
            })
        })
        .catch(err => {
          throw err
        })
    },
  },
}
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.btn-search-container {
  display: flex;
}

.btn-addproduct {
  align-self: flex-start;
  margin-right: 1.5rem !important;
}
</style>
