<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4"> </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <!-- Card header -->
        <b-card-header>
          <h2 slot="header" class="mb-0">
            แก้ไขโปรโมชั่น
            <p class="mb-0">แก้ไขรายละเอียดของโปรโมชั่น</p>
          </h2>
        </b-card-header>

        <!-- Card body -->
        <b-card-body v-if="!loading">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ชื่อโปรโมชั่น</label
                >
                <b-col md="8">
                  <base-input
                    name="name"
                    placeholder="ใส่ชื่อโปรโมชั่น"
                    :rules="{ required: true }"
                    :custom-messages="NameMessages"
                    v-model="promotion.name"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >รายละเอียดโปรโมชั่น</label
                >
                <b-col md="8">
                  <base-input name="description">
                    <textarea
                      class="form-control"
                      rows="10"
                      placeholder="ใส่รายละเอียดโปรโมชั่น"
                      v-model="promotion.description"
                      resize="none"
                    ></textarea>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ระยะเวลาโปรโมชั่น</label
                >
                <b-col md="3">
                  <base-input
                    :rules="{ required: true }"
                    :custom-messages="DateMessages"
                  >
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{
                        allowInput: true,
                        enableTime: true,
                        time_24hr: true,
                      }"
                      class="form-control datepicker"
                      v-model="startDate"
                      placeholder="วันที่เริ่มโปรโมชั่น"
                    >
                    </flat-picker>
                  </base-input>
                </b-col>
                <b-col md="3">
                  <base-input
                    :rules="{ required: true }"
                    :custom-messages="DateMessages"
                  >
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{
                        allowInput: true,
                        enableTime: true,
                        time_24hr: true,
                      }"
                      class="form-control datepicker"
                      v-model="endDate"
                      placeholder="วันที่สิ้นสุดโปรโมชั่น"
                    >
                    </flat-picker>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ภาพปกโปรโมชั่น</label
                >
                <b-col md="3" v-if="promotion.promotion_image_url">
                  <div class="img-container">
                    <img
                      v-bind:src="promotion.promotion_image_url"
                      alt=""
                      class=""
                    />
                  </div>
                </b-col>

                <b-col md="3">
                  <dropzone-file-upload
                    v-model="img"
                    id="dzMessageCover"
                    required
                  ></dropzone-file-upload>
                </b-col>
              </b-row>

              <base-button
                size="md"
                outline
                type="default"
                @click.native="goBack"
                >ย้อนกลับ</base-button
              >
              <base-button type="primary" native-type="submit"
                >บันทึก</base-button
              >
            </form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
import { Dropzone } from 'dropzone';
import axios from 'axios';
import NProgress from 'nprogress';
import flatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import jwtDecode from 'jwt-decode';

Dropzone.options.dzMessageCover = {
  dictDefaultMessage: '* ใส่ภาพปก',
};

Dropzone.options.dzMessageOther = {
  dictDefaultMessage: 'ใส่รูปภาพเพิ่มเติม',
};

export default {
  components: {
    DropzoneFileUpload,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
  },
  data() {
    return {
      validated: false,
      shopInfo: null,
      userInfo: null,
      promotion: null,
      img: [],
      loading: true,
      startDate: null,
      endDate: null,
      NameMessages: {
        required: 'กรุณาใส่ชื่อโปรโมชั่น',
      },
      DateMessages: {
        required: 'กรุณาใส่วันที่',
      },
    };
  },
  created() {
    this.promotion = JSON.parse(localStorage.getItem('EditPromotion'));
    this.startDate = this.getDateTime(this.promotion.startDate);
    this.endDate = this.getDateTime(this.promotion.endDate);
    this.loading = false;
  },
  methods: {
    getDateTime(date) {
      let newDate = date.substring(0, 10) + ' ' + date.substring(11, 16);
      return newDate;
    },
    processImg(value) {
      value = this.img;
      const data = new FormData();
      data.append('file', value[0]);
      var config = {
        method: 'post',
        url: 'https://api-ft-production.devfinite.solutions/v1/files/upload/promotion',
        data: data,
      };

      const that = this;
      axios(config)
        .then(function (response) {
          that.promotion.promotion_image_url = response.data.url;
        })
        .catch(function (error) {
          throw new Error(error);
        });
    },
    async onSubmit() {
      // Form submit here
      NProgress.start();
      if (this.img !== '') {
        this.processImg();
      }
      setTimeout(async () => {
        let promoObj = {};
        promoObj.startDate = this.convertToISO(this.startDate);
        promoObj.endDate = this.convertToISO(this.endDate);
        promoObj.name = this.promotion.name;
        promoObj.description = this.promotion.description;
        promoObj.shopId = this.$store.state.shop.shop.id;
        promoObj.promotion_image_url = this.promotion.promotion_image_url;
        promoObj.status = this.promotion.status;
        await this.$store
          .dispatch('shop/editPromotion', {
            id: this.promotion.id,
            obj: promoObj,
          })
          .then(() => {
            this.$router.push({
              name: 'Promotion List',
            });
          })
          .catch(err => {
            this.refreshErrorhandle(error);
          })
          .finally(() => NProgress.done());
      }, 500);
    },
    convertToISO(selectedDate) {
      const date = new Date(selectedDate);
      return date.toISOString();
    },
    goBack(index, row) {
      localStorage.removeItem('EditPromotion');
      this.$router.push({ name: 'Promotion List' });
    },
    refreshErrorhandle(error) {
      const err = JSON.parse(JSON.stringify(error));
      const user = JSON.parse(localStorage.getItem('user'));
      if (err.status === 445) {
        this.$store
          .dispatch('user/refreshToken', user)
          .then(() => {
            this.$store.dispatch('shop/getNewUrl').then(() => {});
            this.onSubmit();
          })
          .catch(err => {
            throw err;
          });
      } else if (err.status === 446) {
        window.alert('Session Expired\nกรุณา Login ใหม่');
        this.$store.dispatch('user/logOut');
      }
    },
  },
};
</script>

<style></style>
