<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links" v-if="admin">
        <sidebar-item
          :link="{
            name: 'รายชื่อร้านค้า',
            path: '/dashboard/admin/admin-dashboard/shop-list',
            icon: 'ni ni-shop text-primary',
          }"
        />
        <!-- <sidebar-item
            :link="{ name: 'การจัดส่ง', path: '/dashboard/order-list' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'สินค้า', path: '/dashboard/product-list' }"
          ></sidebar-item> 
        </sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'รายการสินค้า',
            path: '/dashboard/admin/admin-dashboard/admin-product-list',
            icon: 'ni ni-cart text-default',
          }"
        />

        <sidebar-item
          :link="{
            name: 'อีเว้นท์',
            path: '/dashboard/admin/admin-dashboard/event-list',
            icon: 'ni ni-bell-55 text-yellow',
          }"
        />

        <sidebar-item
          :link="{
            name: 'โปรโมชั่น',
            path: '/dashboard/admin/admin-dashboard/promotion-lists',
            icon: 'ni ni-notification-70 text-info',
          }"
        />

        <!-- <sidebar-item
          :link="{
            name: 'แก้ไขโปรไฟล์',
            path: '/dashboard/edit-profile',
            icon: 'ni ni-settings-gear-65 text-orange',
          }"
        /> -->
      </template>

      <template slot="links" v-else>
        <sidebar-item
          :link="{
            name: 'รายการสินค้า',
            icon: 'ni ni-shop text-primary',
          }"
        >
          <sidebar-item
            :link="{
              name: 'สินค้าทั้งหมด',
              path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/product-list`,
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'หมวดหมู่สินค้า',
              path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/add-product-categories`,
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'ส่วนลด',
            path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/discount-list`,
            icon: 'fa fa-percent text-success',
          }"
        />

        <sidebar-item
          :link="{
            name: 'โปรโมชั่น',
            path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/promotion-list`,
            icon: 'ni ni-notification-70 text-info',
          }"
        />

        <sidebar-item
          :link="{
            name: 'แก้ไขโปรไฟล์',
            path: `/dashboard/${this.$route.params.UserId}/${this.$route.params.ShopId}/edit-profile`,
            icon: 'ni ni-settings-gear-65 text-orange',
          }"
        />

        <!-- <sidebar-item
          :link="{
            name: 'Examples',
            icon: 'ni ni-ungroup text-orange',
          }"
        >
          <sidebar-item :link="{ name: 'Pricing', path: '/pricing' }" />
          <sidebar-item :link="{ name: 'Login', path: '/login' }" />
          <sidebar-item :link="{ name: 'Register', path: '/register' }" />
          <sidebar-item :link="{ name: 'Lock', path: '/lock' }" />
          <sidebar-item :link="{ name: 'Timeline', path: '/pages/timeline' }" />
          <sidebar-item :link="{ name: 'Profile', path: '/pages/user' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Components',
            icon: 'ni ni-ui-04 text-info',
          }"
        >
          <sidebar-item
            :link="{ name: 'Buttons', path: '/components/buttons' }"
          />
          <sidebar-item :link="{ name: 'Cards', path: '/components/cards' }" />
          <sidebar-item
            :link="{ name: 'Grid', path: '/components/grid-system' }"
          />
          <sidebar-item
            :link="{ name: 'Notifications', path: '/components/notifications' }"
          />
          <sidebar-item :link="{ name: 'Icons', path: '/components/icons' }" />
          <sidebar-item
            :link="{ name: 'Typography', path: '/components/typography' }"
          />

          <sidebar-item :link="{ name: 'Multi Level' }">
            <sidebar-item :link="{ name: 'Third level menu', path: '#!' }" />
            <sidebar-item :link="{ name: 'Just another link', path: '#a' }" />
            <sidebar-item :link="{ name: 'One last link', path: '#b' }" />
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Forms',
            icon: 'ni ni-single-copy-04 text-pink',
          }"
        >
          <sidebar-item :link="{ name: 'Elements', path: '/forms/elements' }" />
          <sidebar-item
            :link="{ name: 'Components', path: '/forms/components' }"
          />
          <sidebar-item
            :link="{ name: 'Validation', path: '/forms/validation' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Tables',
            icon: 'ni ni-align-left-2 text-default',
          }"
        >
          <sidebar-item :link="{ name: 'Tables', path: '/tables/regular' }" />
          <sidebar-item
            :link="{ name: 'Sortable', path: '/tables/sortable' }"
          />
          <sidebar-item
            :link="{ name: 'Paginated Tables', path: '/tables/paginated' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Maps',
            icon: 'ni ni-map-big text-primary',
          }"
        >
          <sidebar-item :link="{ name: 'Google', path: '/maps/google' }" />
          <sidebar-item :link="{ name: 'Vector', path: '/maps/vector' }" />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Widgets',
            icon: 'ni ni-archive-2 text-green',
            path: '/widgets',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Charts',
            icon: 'ni ni-chart-pie-35 text-info',
            path: '/charts',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Calendar',
            icon: 'ni ni-calendar-grid-58 text-red',
            path: '/calendar',
          }"
        >
        </sidebar-item> -->
      </template>

      <template slot="links-after">
        <hr class="my-3" />
        <b-nav class="navbar-nav mb-md-3">
          <b-nav-item href="https://ft-ecommerce.devfinite.solutions">
            <i class="ni ni-spaceship"></i>
            <b-nav-text class="p-0">หน้าเว็บ Fortune Town</b-nav-text>
          </b-nav-item>
        </b-nav>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { mapState } from 'vuex';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  created() {
    this.checkAdmin();
  },
  mounted() {
    this.$store.state.shop.apiClientAuth = axios.create({
      baseURL: 'https://api-ft-production.devfinite.solutions/',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // prettier-ignore
        'Authorization': localStorage.getItem('user') ? `Bearer ${JSON.parse(localStorage.getItem('user')).token}` : '',
      },
    });
  },
  data() {
    return {
      admin: false,
      user: {},
    };
  },
  computed: {},
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
    checkAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      const decoded = jwtDecode(user.token);
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      };

      this.user = userData;

      if (userData.roleName == 'Admin') {
        this.admin = true;
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
