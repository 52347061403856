var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"align-items-center py-4"})],1),_c('div',{staticClass:"container-fluid mt--6"},[_c('b-card',{staticClass:"mb-4",attrs:{"no-body":""}},[_c('b-card-header',[_c('h2',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v(" แก้ไขอีเว้นท์ "),_c('p',{staticClass:"mb-0"},[_vm._v("แก้ไขรายละเอียดของอีเว้นท์")])])]),(!_vm.loading)?_c('b-card-body',[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',{staticClass:"form-group"},[_c('label',{staticClass:"col-md-2 col-form-label form-control-label"},[_vm._v("* ชื่ออีเว้นท์")]),_c('b-col',{attrs:{"md":"8"}},[_c('base-input',{attrs:{"name":"name","placeholder":"ใส่ชื่ออีเว้นท์","rules":"required"},model:{value:(_vm.event.name),callback:function ($$v) {_vm.$set(_vm.event, "name", $$v)},expression:"event.name"}})],1)],1),_c('b-row',{staticClass:"form-group"},[_c('label',{staticClass:"col-md-2 col-form-label form-control-label"},[_vm._v("รายละเอียดอีเว้นท์")]),_c('b-col',{attrs:{"md":"8"}},[_c('base-input',{attrs:{"name":"description"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.description),expression:"event.description"}],staticClass:"form-control",attrs:{"rows":"10","placeholder":"ใส่รายละเอียดอีเว้นท์","resize":"none"},domProps:{"value":(_vm.event.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.event, "description", $event.target.value)}}})])],1)],1),_c('b-row',{staticClass:"form-group"},[_c('label',{staticClass:"col-md-2 col-form-label form-control-label"},[_vm._v("Link อีเว้นท์")]),_c('b-col',{attrs:{"md":"8"}},[_c('base-input',{attrs:{"name":"name","placeholder":"ใส่ link อีเว้นท์"},model:{value:(_vm.event.link),callback:function ($$v) {_vm.$set(_vm.event, "link", $$v)},expression:"event.link"}})],1)],1),_c('b-row',{staticClass:"form-group"},[_c('label',{staticClass:"col-md-2 col-form-label form-control-label"},[_vm._v("* ระยะเวลาอีเว้นท์")]),_c('b-col',{attrs:{"md":"3"}},[_c('base-input',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
                      allowInput: true,
                      enableTime: true,
                      time_24hr: true,
                    },"placeholder":"วันที่เริ่มอีเว้นท์"},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('base-input',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var focus = ref.focus;
                    var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
                      allowInput: true,
                      enableTime: true,
                      time_24hr: true,
                    },"placeholder":"วันที่สิ้นสุดอีเว้นท์"},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})}}],null,true)})],1)],1),_c('b-row',{staticClass:"form-group"},[_c('label',{staticClass:"col-md-2 col-form-label form-control-label"},[_vm._v("* ภาพปกอีเว้นท์")]),(_vm.event.event_img_url)?_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":_vm.event.event_img_url,"alt":""}})])]):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('dropzone-file-upload',{attrs:{"id":"dzMessageCover","required":""},model:{value:(_vm.img),callback:function ($$v) {_vm.img=$$v},expression:"img"}})],1)],1),_c('base-button',{attrs:{"size":"md","outline":"","type":"default"},nativeOn:{"click":function($event){return _vm.goBack.apply(null, arguments)}}},[_vm._v("ย้อนกลับ")]),_c('base-button',{attrs:{"type":"primary","native-type":"submit"}},[_vm._v("บันทึก")])],1)]}}],null,false,3159634564)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }