<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">รายการส่วนลด</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>

        <b-col lg="6" cols="5" class="text-right">
          <base-button size="md" type="primary" @click.native="goBack"
            >ย้อนกลับ</base-button
          >
          <base-button size="md" type="neutral" @click.native="onSubmit"
            >ยืนยันสินค้าเข้าร่วม</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <discount-checkbox
        :tableProductData="tableProductData"
        :selected="productsDiscount"
        @selectedProducts="getSelectedProduct"
      ></discount-checkbox>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import { BasePagination } from '@/components';
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin';
import jwtDecode from 'jwt-decode';
import DiscountCheckbox from './CheckboxDiscount.vue';
import NProgress from 'nprogress';
import axios from 'axios';
// import users from './users2'

export default {
  mixins: [clientPaginationMixin],
  components: {
    // BasePagination,
    RouteBreadCrumb,
    DiscountCheckbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    this.apiClientAuth = axios.create({
      baseURL: 'https://api-ft-production.devfinite.solutions/',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // prettier-ignore
        'Authorization': localStorage.getItem('user') ? `Bearer ${JSON.parse(localStorage.getItem('user')).token}` : '',
      },
    });
  },
  mounted() {
    this.getUserInfo();
    this.selectedDiscount = JSON.parse(
      localStorage.getItem('selectedDiscount')
    );

    this.apiClientAuth
      .get(`v1/product-discount/${this.$route.params.discountId}`)
      .then(res => {
        this.productsDiscount = res.data.products;
      })
      .catch(err => {
        console.log(err);
      });
  },
  data() {
    return {
      // propsToSearch: ['name', 'email', 'age'],
      apiClientAuth: null,
      chooseProduct: false,
      loading: true,
      rejected: false,
      approved: false,
      selectedDiscount: {},
      propsToSearch: ['name'],
      // tableColumns: [
      //   {
      //     type: 'selection',
      //   },
      //   {
      //     prop: 'startingDate',
      //     label: 'วันที่เริ่ม',
      //     minWidth: 100,
      //     sortable: true,
      //   },
      //   {
      //     prop: 'endingDate',
      //     label: 'สิ้นสุดโปรโมชั่น',
      //     minWidth: 100,
      //     sortable: true,
      //   },
      //   {
      //     prop: 'name',
      //     label: 'รายชื่อโปรโมชั่น',
      //     minWidth: 250,
      //     sortable: true,
      //   },
      //   {
      //     prop: 'status',
      //     label: 'สถานะ',
      //     minWidth: 100,
      //     sortable: true,
      //   },
      // ],
      // tableData: [],
      selectedRows: [],
      selectedProducts: [],
      tableProductData: [],
      productsDiscount: [],
    };
  },
  methods: {
    async onSubmit() {
      const numberOfProducts = this.selectedProducts.length;
      let count = 0;
      NProgress.start();

      //ถ้าในรายการส่วนลดมี แต่อันใหม่ไม่มีแล้ว = delete
      let deleteFromDiscount = this.productsDiscount.filter(
        x => !this.selectedProducts.some(y => y.id == x.id)
      );

      while (count < deleteFromDiscount.length) {
        deleteFromDiscount[count].discountId = null;

        this.$store.dispatch('shop/editProduct', deleteFromDiscount[count]);

        count++;
      }
      count = 0;
      while (count !== numberOfProducts) {
        this.selectedProducts[count].discountId =
          +this.$route.params.discountId;

        this.$store.dispatch('shop/editProduct', this.selectedProducts[count]);

        count++;
      }
      this.goBack();
      NProgress.done();
    },
    goBack(index, row) {
      this.chooseProduct = !this.chooseProduct;
      this.$router.push({ name: 'Discount List' });
      localStorage.removeItem('selectedDiscount');
    },
    getSelectedProduct(value) {
      this.selectedProducts = value;
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    convertStartDate(data, date) {
      data.startingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    },
    convertEndDate(data, date) {
      data.endingDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    },
    async getUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'));
      const decoded = jwtDecode(user.token);
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      };

      await this.$store
        .dispatch('user/getUserInfo', userData.id)
        .then(() => {
          this.userInfo = this.$store.state.user.user.info;
          this.getShopInfo();
        })
        .catch(err => {
          throw err;
        });
    },
    async getShopInfo() {
      await this.$store
        .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
        .then(() => {
          this.shopInfo = this.$store.state.shop.shop;
          this.getProductByShop();
        })
        .catch(err => {
          throw err;
        });
    },
    async getProductByShop() {
      await this.$store.dispatch(
        'shop/getProductByShop',
        this.$store.state.user.user.info.shopId
      );
      this.tableProductData = this.$store.state.shop.shop.products;
    },
  },
  computed: {
    noRejectList(row) {
      return this.tableData.filter(
        notRejected => notRejected.status !== 'Rejected'
      );
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
