<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4"> </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <b-card-header>
          <h2 slot="header" class="mb-0">
            แก้ไขสินค้า
            <p class="mb-0">โปรดกรอกข้อมูลของสินค้าของคุณ</p>
          </h2>
        </b-card-header>

        <!-- Card body -->
        <b-card-body v-if="!loading">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ชื่อสินค้า</label
                >
                <b-col md="8">
                  <base-input
                    name="name"
                    placeholder="ใส่ชื่อสินค้า"
                    rules="required"
                    v-model="product.name"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >รายละเอียดสินค้า</label
                >
                <b-col md="8">
                  <base-input
                    name="description"
                    placeholder="ใส่รายละเอียดสินค้า"
                  >
                    <textarea
                      class="form-control"
                      rows="10"
                      placeholder="ใส่รายละเอียดสินค้า..."
                      v-model="product.description"
                      resize="none"
                    ></textarea>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* หมวดหมู่</label
                >
                <b-col md="4">
                  <base-input>
                    <el-select
                      v-model="product.categoryId"
                      filterable
                      placeholder="เลือกหมวดหมู่"
                    >
                      <el-option
                        v-for="category in categories"
                        :key="category.name"
                        :label="category.name"
                        :value="category.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ราคา</label
                >
                <b-col md="2">
                  <base-input
                    name="price"
                    type="number"
                    min="0"
                    placeholder="บาท"
                    rules="required"
                    v-model.number="product.price"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* จำนวน</label
                >
                <b-col md="2">
                  <base-input
                    name="quantity"
                    type="number"
                    min="0"
                    placeholder=""
                    rules="required"
                    v-model.number="product.inventory.quantity"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* ภาพสินค้า</label
                >
                <b-col md="3">
                  <!-- <base-input
                    name="Product Img"
                    placeholder="ใส่ url รูปภาพสินค้า"
                    rules="required"
                    v-model="product.product_image_url"
                  >
                  </base-input> -->
                  <div class="img-container">
                    <img
                      v-bind:src="product.product_image_url"
                      alt=""
                      class=""
                    />
                  </div>
                </b-col>

                <b-col md="3">
                  <dropzone-file-upload
                    v-model="img"
                    id="dzMessageCover"
                    required
                  ></dropzone-file-upload>
                </b-col>

                <!-- <b-col md="6">
                  <dropzone-file-upload
                    v-model="product.img.other"
                    id="dzMessageOther"
                    multiple
                  ></dropzone-file-upload>
                </b-col> -->
              </b-row>

              <b-row class="form-group">
                <label class="col-md-2 col-form-label form-control-label"
                  >* SKU</label
                >
                <b-col md="3">
                  <base-input
                    name="SKU"
                    placeholder="-"
                    rules="required"
                    v-model="product.SKU"
                  >
                  </base-input>
                </b-col>
              </b-row>

              <base-button
                class="mt-4"
                size="md"
                outline
                type="default"
                @click.native="goBack"
                >ย้อนกลับ</base-button
              >
              <base-button type="primary" native-type="submit" class="mt-4"
                >บันทึก</base-button
              >
            </form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
import { Dropzone } from 'dropzone';
import NProgress from 'nprogress';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

Dropzone.options.dzMessageCover = {
  dictDefaultMessage: '* ใส่ภาพปก',
};

Dropzone.options.dzMessageOther = {
  dictDefaultMessage: 'ใส่รูปภาพเพิ่มเติม',
};

export default {
  components: {
    DropzoneFileUpload,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      validated: false,
      product: null,
      categories: [],
      img: [],
      loading: true,
    };
  },
  created() {
    if (!this.checkAdmin) this.getUserInfo();
    this.product = JSON.parse(localStorage.getItem('editProduct'));
    if (this.checkAdmin) this.loading = false;
  },
  methods: {
    checkAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      const decoded = jwtDecode(user.token);
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      };

      if (userData.roleName == 'Admin') {
        return true;
      } else return false;
    },
    processImg(value) {
      value = this.img;
      const data = new FormData();
      data.append('file', value[0]);
      var config = {
        method: 'post',
        url: 'https://api-ft-production.devfinite.solutions/v1/files/upload/shop',
        data: data,
      };

      const that = this;
      axios(config)
        .then(function (response) {
          that.product.product_image_url = response.data.url;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async onSubmit() {
      // Form submit here
      NProgress.start();
      if (this.img && this.img !== null && this.img.length != 0) {
        this.processImg();
      }
      setTimeout(async () => {
        await this.$store
          .dispatch('shop/editInventory', this.product.inventory)
          .then(() => {
            this.$store.dispatch('shop/editProduct', this.product).then(() => {
              localStorage.removeItem('editProduct');
              if (!this.checkAdmin())
                this.$router.push({
                  name: 'Product List',
                });
              else
                this.$router.push({
                  path:
                    '/dashboard/admin/admin/shops/' +
                    this.product.shopId +
                    '/products',
                });
            });
          })
          .catch(error => {
            this.refreshErrorhandle(error);
          })
          .finally(() => NProgress.done());
      }, 500);
    },
    filesChange(files) {
      this.inputs.file = files;
    },
    goBack(index, row) {
      localStorage.removeItem('editProduct');

      if (this.checkAdmin()) this.$router.push({ name: 'Admin Products List' });
      else
        this.$router.push({
          name: 'Product List',
        });
    },
    async getProductCategories() {
      await this.$store
        .dispatch('shop/getProductCategories', this.$store.state.shop.shop.id)
        .then(responses => {
          responses.forEach(res =>
            this.categories.push({
              id: res.id,
              name: res.name,
            })
          );
          this.loading = false;
        })
        .catch(error => console.log(error));
    },
    refreshErrorhandle(error) {
      console.log(error);
      const err = JSON.parse(JSON.stringify(error));
      console.log(err);
      const user = JSON.parse(localStorage.getItem('user'));
      if (err.status === 445) {
        this.$store
          .dispatch('user/refreshToken', user)
          .then(() => {
            this.$store.dispatch('shop/getNewUrl').then(() => {});
          })
          .catch(err => {
            throw err;
          });
      } else if (err.status === 446) {
        window.alert('Session Expired\nกรุณา Login ใหม่');
        this.$store.dispatch('user/logOut');
      }
    },
    async getUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'));
      const decoded = jwtDecode(user.token);
      const userData = {
        id: decoded.sub,
        username: decoded.username,
        roleName: decoded.role,
      };

      await this.$store
        .dispatch('user/getUserInfo', userData.id)
        .then(() => {
          this.userInfo = this.$store.state.user.user.info;
          this.getShopInfo();
        })
        .catch(err => {
          throw err;
        });
    },
    async getShopInfo() {
      await this.$store
        .dispatch('shop/getShopInfo', this.$store.state.user.user.info.shopId)
        .then(() => {
          this.shopInfo = this.$store.state.shop.shop;
          this.getProductCategories();
          this.getProductByShop();
        })
        .catch(err => {
          throw err;
        });
    },
    async getProductByShop() {
      await this.$store.dispatch(
        'shop/getProductByShop',
        this.$store.state.user.user.info.shopId
      );
    },
  },
};
</script>

<style>
.img-container {
  width: 13rem;
  height: 13rem;
  object-fit: cover;
}

.img-container img {
  width: 100%;
}
</style>
