import Service from '../../services/Service.js';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

const apiClientAuth = axios.create({
  baseURL: 'https://api-ft-production.devfinite.solutions',
  withCredentials: false,
});

apiClientAuth.interceptors.request.use(
  async config => {
    const value = await JSON.parse(localStorage.getItem('user'));
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // prettier-ignore
      'Authorization': localStorage.getItem('user') ? `Bearer ${value.token}` : '',
    };
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

apiClientAuth.interceptors.response.use(
  response => response,
  async error => {
    const err = JSON.parse(JSON.stringify(error));
    const user = JSON.parse(localStorage.getItem('user'));
    if (err.status === 445) {
      await this.$store
        .dispatch('user/refreshToken', user)
        .then(() => {
          store.dispatch('shop/getNewUrl').then(() => {});
        })
        .catch(err => {
          throw err;
        });
    } else if (err.status === 446) {
      window.alert('Session Expired\nกรุณา Login ใหม่');
      store.dispatch('user/logOut');
    }
  }
);

export const namespaced = true;

export const state = {
  user: null,
  shops: null,
  events: null,
  promotions: null,
};

export const mutations = {
  SET_SHOPS(state, shopList) {
    state.shops = shopList;
  },
  ADD_SHOP(state, shop) {
    if (state.shops === null) {
      state.shops = shop;
    } else {
      state.shops.push(shop);
    }
  },
  SET_EVENTS(state, eventList) {
    state.events = eventList;
  },
  ADD_EVENT(state, event) {
    if (state.events === null) {
      state.events = event;
    } else {
      state.events.push(event);
    }
  },
  SET_PROMOTIONS(state, promotionList) {
    state.promotions = promotionList;
  },
  EDIT_SHOP(state, shop) {
    state.shops.forEach(eachShop => {
      if (eachShop.id === shop.id) {
        eachShop = shop;
      }
    });
  },
  EDIT_PROMOTION(state, promotion) {
    state.promotions.forEach(promo => {
      if (promo.id === promotion.id) {
        promo = promotion;
      }
    });
  },
  DELETE_SHOP(state, shop) {
    const index = state.shops.findIndex(eachShop => eachShop.id === shop.id);

    state.shops.splice(index, 1);
  },
  DELETE_EVENT(state, event) {
    const index = state.events.findIndex(
      eachEvent => eachEvent.id === event.id
    );

    state.events.splice(index, 1);
  },
};

export const actions = {
  async getShops({ commit }) {
    try {
      const { data } = await Service.getShop();
      const eventList = data.results;
      commit('SET_SHOPS', eventList);
    } catch (err) {
      throw err;
    }
  },
  async getEvents({ commit }) {
    try {
      const { data } = await Service.getEvent();
      const eventList = data.results;
      commit('SET_EVENTS', eventList);
    } catch (err) {
      throw err;
    }
  },
  async getPromotions({ commit }) {
    try {
      const { data } = await Service.getPromotion();
      const promotionList = data.results;
      commit('SET_PROMOTIONS', promotionList);
    } catch (err) {
      throw err;
    }
  },
  async approvePromotion({ commit }, promo) {
    try {
      const { data } = await apiClientAuth.patch(`v1/promotions/${promo.id}`, {
        name: promo.name,
        promotion_image_url: promo.promotion_image_url,
        description: promo.description,
        shopId: promo.shopId,
        status: 'Approved',
        startDate: promo.startDate,
        endDate: promo.endDate,
      });
      commit('EDIT_PROMOTION', data);
    } catch (err) {
      throw err;
    }
  },
  async rejectPromotion({ commit }, promo) {
    try {
      const { data } = await apiClientAuth.patch(`v1/promotions/${promo.id}`, {
        name: promo.name,
        promotion_image_url: promo.promotion_image_url,
        description: promo.description,
        shopId: promo.shopId,
        status: 'Rejected',
        startDate: promo.startDate,
        endDate: promo.endDate,
      });
      commit('EDIT_PROMOTION', data);
    } catch (err) {
      throw err;
    }
  },
  async createShop({ commit }, shop) {
    try {
      const { data } = await apiClientAuth.post(`v1/shops`, shop);
      commit('ADD_SHOP', data);
    } catch (err) {
      throw err;
    }
  },
  async editShop({ commit }, shop) {
    try {
      const { data } = await apiClientAuth.patch(`v1/shops/${shop.id}`, {
        name: shop.name,
        description: shop.description,
        profile_image_url: shop.profile_image_url,
        categoryId: shop.categoryId,
        phoneNumber: shop.phoneNumber,
        address: shop.address,
        lineId: shop.lineId,
        fbName: shop.fbName,
        website: shop.website,
      });
      commit('EDIT_SHOP', data);
    } catch (err) {
      throw err;
    }
  },
  async createEvent({ commit }, event) {
    try {
      const { data } = await apiClientAuth.post(`v1/events`, event);
      commit('ADD_EVENT', data);
    } catch (err) {
      throw err;
    }
  },
  async editEvent({}, event) {
    try {
      const { data } = await apiClientAuth.patch(
        `v1/events/${event.id}`,
        event.event
      );
    } catch (err) {
      throw err;
    }
  },
  async deleteEvent({ commit }, eventId) {
    const { data } = await apiClientAuth.delete(`v1/events/${eventId}`);
  },
  async deleteShop({ commit }, shopId) {
    const { data } = await apiClientAuth.delete(`v1/shops/${shopId}`);
  },
};
